import React, {useState, useRef, useEffect, memo} from 'react';


import {VideoPlayer} from './video-player'

import {PostComponent} from './post';
import {InvitationComponent} from './invitation';

import {dict2arr} from "../../utils/fetch.utils.js";

import "./scss/feed.scss"

export const FeedComponent = ({items, controlsCallback, currentlyActiveItem,currentOpenChat,toggleChatCallback,chatMessageSubmitHandler,contentActionsClickHandler,invitesHandler})=>{

	var feedContainerRef = useRef(null);
	var [debounceTimeout, setDebounceTimeout ] = useState(null);
	// var [scrollY, setScrollY] = useState(0);




	const offsetsSortFunc = (x, y)=> {
		if (Math.abs(x[1]) < Math.abs(y[1])) {
			return -1;
		} 
		if (Math.abs(x[1]) > Math.abs(y[1])) {
			return 1;
		}
		return 0;
	}


	const debounce = (func, timeout)=>{

	  // console.log('calling debounce', debounceTimeout)


	  return (...args) => {
	  	// console.log('return funcs')
	    if (debounceTimeout === null) {
			// console.log('calling fun')	    	
	      	func.apply(this, args);
	    }
	    clearTimeout(debounceTimeout);
	    setDebounceTimeout(setTimeout(() => {
	      clearTimeout(debounceTimeout)	
	      setDebounceTimeout(null);
	    }, timeout));
	  };



	  // return (...args) => {
	  //   clearTimeout(debounceTimeout);
	  //   setDebounceTimeout(setTimeout(() => { console.log('fnc');func.apply(this, args); }, timeout));
	  // };
	}


	const scrollWhellEvents = (event)=>
	{
		var dist = event.deltaY,
			container =feedContainerRef.current
		
		let post_keys = dict2arr(items);
		let offsets = 
			post_keys
				.map((item)=> [item.key, document.getElementById(item.key).offsetTop - container.scrollTop])
				.sort(offsetsSortFunc)
		
		if (offsets.length === 0) return; // nothing to scroll


		let currentPostKey = offsets[0][0]
		// let nextPostKey = post_keys.filter((el, i, arr)=>arr[i>0? i-1] == currentPostKey )

		console.log(offsets)



		// let offsets = 
			
		// 	dict2arr(items)
		// 		.map((item)=>{
		// 			let top = document.getElementById(item.key).offsetTop
		// 			return [item.key, top, ]
		// 		});


		// console.log('scrollWhellEvents', offsets);

		// console.log(dist)
		// container.scroll(0, dist +  container.scrollTop);

		debounce(()=>{
			if (dist > 0)
			{
				console.log('scroll to next')
			}
			else
			{
				console.log('scroll to prev')
			}

		}, 1000)()
		
		// clearTimeout(scrollTimeout);

		// setScrollTimeout(setTimeout(()=>{
		// 	console.log('autoscropp', offsets[0])
		// 	container.scroll(0, container.scrollTop + offsets[0][1]);

		// }, 1000))

		
	}





	// useEffect(()=>{
    //   window.addEventListener('wheel', scrollWhellEvents)
    //   return ()=>{
    //   	window.removeEventListener('wheel', scrollWhellEvents)


    //   }


  	// })





	return (
		<div
			ref={feedContainerRef}
			className="feed-container"
		>

		{items.map((item)=>{


			if (item.is_invitation)
				return (
					<InvitationComponent 
						key={item.key}
						item={item}
						invitesHandler={invitesHandler}
						controlsCallback={controlsCallback}
						currentlyPlaying={ currentlyActiveItem[0]==item.key ? [true, currentlyActiveItem[1] ] : [null, null]   }
					/>
				)

			else

				return (
					<PostComponent
						key={item.key}
						item={item}
						controlsCallback={controlsCallback}

						// make sure we always pass [bool, bool] to avoid 
						// re-renders whenver something irrelevant chages
						currentlyPlaying={ currentlyActiveItem[0]==item.key ? [true, currentlyActiveItem[1] ] : [null, null]   }
						chatOpen={currentOpenChat == item.key}
						toggleChatCallback={(open)=>toggleChatCallback(item.key, open)}
						chatMessageSubmitHandler={chatMessageSubmitHandler}
						contentActionsClickHandler={contentActionsClickHandler}

					/>
					)

		} )}
			

		</div>

		)
}