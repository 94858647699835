
import config from '../../utils/config.app.js';
import React, {useState, useRef, useEffect} from 'react';
// import Nav from 'react-bootstrap/Nav';

import User from '../../utils/userdata.app.js'


import Modal from 'react-bootstrap/Modal';

export const DeleteConfirmation = ({show, cancelCallback, confirmCallback})=>
{
	return (
		<Modal 
		      	className="upload-modal"
		      	dialogClassName={"small-modal"}
		      	show={show} 
		      	fullscreen={false}
		      	// onShow={prepareContentKey}
			>

			 <Modal.Header>
		      {/*<Modal.Title>Modal title</Modal.Title>*/}
			  	{/*<h3>Do you want to delete this collab?</h3>*/}
		    </Modal.Header>

		    <Modal.Body>
		      {/*<p>Modal body text goes here.</p>*/}
		      <h3>Do you want to delete this collab?</h3>
		    </Modal.Body>

		    <Modal.Footer>
		      <button 
		      	className="button"
		      	onClick={cancelCallback}
		      >
		      	Cancel
		      </button>
		      <button 
		      	className="button button-primary"
		      	onClick={confirmCallback}
		      >
		      	Delete
		      </button>
		    </Modal.Footer>
		</Modal>
		
	);


}
