import React, {useState, useEffect, useRef, useMemo} from 'react';
import { createPortal } from "react-dom";


import { RichTextarea as BaseRichTextarea,
  createRegexRenderer,
  RichTextareaHandle} from "rich-textarea";

import {UserBar} from './user-bar.component'



import '../components-css/rich-textarea.scss'
import '../components-css/auto-complete.scss'






export const RichTextarea = ({placeholder, textareaElementId, onTextChange, onInvitesChange, autoCompleteDelay=500, mentionRegExp, performUserSearch, recentInvites})=>{

	const wrapper = useRef(null);
	const ref = useRef(null);
	const [text, setText] = useState("");
	const [pos, setPos] = useState(null); 


	const [index, setIndex] = useState(0);
	var [autoCompleteItems, setAutoCompleteItems] = useState([]);
	// var [mentionsList, setMentionsList] = useState([]);
	var [invitesList, setInvitesList] = useState([]);


	// var [searchStr, setSearchStr] = useState(null);
	var [autoCompleteDelay, setAutoCompleteDelay] = useState(null);

	var [friendsList, setFriendsList] = useState([]);


	useEffect(()=>{
		// console.log('useEffect: recentInvites', recentInvites)
		setFriendsList(recentInvites);
	},[recentInvites])





  const ensureUniqueUserKeys = (list)=>
  {
  	return list
				.reduce((acc, cur)=>
					// map the array to values only
					acc.map((a)=>a.user_key)
						// see if the current item.value is already in the acc
						.indexOf(cur.user_key)<0 
							? [...acc, cur] // add it 
							: acc, []) // skip it
  }

  const onMention = (item) =>{
  	// this makes sure there are only unique values
  	// invitesList is an array containing dicts of {user_name, user_key, user_avatar}
  	let tmp = ensureUniqueUserKeys([...invitesList, item]);

  	// console.log('onMention', item, tmp);


	  setInvitesList(tmp);	
  	onInvitesChange(tmp);
  }

  const unMention = (name)=>{
  	// console.log('unmention', name);
  	let unMentionedItem = invitesList.filter((item)=>item.name == name)
  	if (unMentionedItem.length==1)
  	{
  		unMentionedItem = unMentionedItem[0];
  		setFriendsList([unMentionedItem,...friendsList])
  	}


  	let newInvitesList = invitesList.filter((item)=>item.name !== name)
  	onInvitesChange(newInvitesList);
  	setInvitesList(newInvitesList);
  	
  }



  const complete = (i) => {


      if (!ref.current || !pos) return;
      const selected = autoCompleteItems[i].name;

      // console.log('complete', i, pos.caret, name)

      ref.current.setRangeText(
        `@${selected} `,
        pos.caret - name.length - 1,
        pos.caret,
        "end"
      );
      setPos(null);
      onMention(autoCompleteItems[i])
      setAutoCompleteItems([])
      setIndex(0);
    };



  const targetText = pos ? text.slice(0, pos.caret) : text;
  const match = pos && targetText.match(mentionRegExp);
  const name = match ?.[1] ?? ""; // If match == true, return match[1] else ""

  const createMentionsRegExp = (mentions)=>{

  	if (mentions.length == 0)
  	{
  		return new RegExp(/undef/);
  	}
  	else
  	{
  		return new RegExp(`(${mentions.map((c) => `@${c}`).join("|")})`,"g");
  	}
  	
  }

	// const MENTION_HIGHLIGHT_REG = new RegExp(`(${['kanye','ozols'].map((c) => `@${c}`).join("|")})`,"g");

	const mentionRenderer = createRegexRenderer([
		[
			// MENTION_HIGHLIGHT_REG,
			// createMentionsRegExp(mentionsList),
			createMentionsRegExp(invitesList.map(item=>item.name)),
			{ 
				// background: "#EAF5F9", 
				// color: "#4276AA", 
				// borderRadius: "3px" 
				fontWeight:600
			},
		],
	]);

	
	const onKeyDown = (e) => {

		 // console.log(e.code)
          // if (!pos || !autoCompleteItems.length) return;
		if (!pos ) return;
          let start = text.lastIndexOf('@', pos.caret);

          
			switch (e.code) {
				case "ArrowUp":
				  e.preventDefault();
				  const nextIndex = index <= 0 ? autoCompleteItems.length - 1 : index - 1;
				  setIndex(nextIndex);
				  break;
				case "ArrowDown":
				  e.preventDefault();
				  const prevIndex = index >= autoCompleteItems.length - 1 ? 0 : index + 1;
				  setIndex(prevIndex);
				  break;
				case "Enter":
				  e.preventDefault();
				  complete(index);
				  break;
				case "Escape":
				  e.preventDefault();
				  setPos(null);
				  setIndex(0);
				  break;

				case "Backspace":
					if (pos !== null)
					{
						let newText = text.slice(0,start+1) + text.slice(pos.caret);
						// console.log('deleting', start, pos.caret );
						// console.log('The new text is -',newText, '-');
						setText(newText)
						unMention(text.slice(start+1, pos.caret))
						
					}
				default:
					
				  break;
			}

        }



  const populateAutocomplete = (searchStr)=>{
  	
  	performUserSearch(searchStr).then((result)=>{
			// console.log('resolved', result)
			setAutoCompleteItems(result)
		})
  }

	
	const onKeyUp = (e) => {
		if (!pos ) return;
    let start = text.lastIndexOf('@', pos.caret);

    let searchStr = text.slice(start+1, pos.caret+3);


    if (autoCompleteDelay != null) clearTimeout(autoCompleteDelay);

    if (searchStr.length > 2)
    {
    	setAutoCompleteDelay(setTimeout((searchStr)=>{
	    	populateAutocomplete(searchStr)
	    }, autoCompleteDelay,searchStr))
    }
  
   
    e.preventDefault();
	}



	const Menu = ({ presentedItems, top, index, complete}) => 
	{
		if (presentedItems.length == 0) return <></>;
	  return (
	    <div
	    	className="auto-complete"
	      
	    >
	      {presentedItems.map((item, i) => (
	        <div
	        	className={"auto-complete-item " +(index === i ? 'active' : '')}
	          key={i}
	          // style={{top:top+'px'}}
	          onMouseDown={(e) => {

	            e.preventDefault();
	            complete(i);
	          }}
	        >
	        	<div
	        		className="avatar"
	        	>
	        		<img src={item.user_avatar} alt={item.name} />
	        	</div>
	        	<span className="name">{item.name}</span>
	        </div>
	      ))}
	    </div>
	  );
	};

	

	const onFriendClick = (item)=>
	{
		setText( text.trim()+' @'+item.name);
		onMention(item);

		// console.log('onFriendClick', friendsList, item.user_key);
		setFriendsList(friendsList.filter(a=>a.user_key != item.user_key))
	}


	const onSelectionChange = (r)=> {
    if (r.focused && mentionRegExp.test(text.slice(0, r.selectionStart))) {
      setPos({top: r.top + r.height, left: r.left, caret: r.selectionStart});
      setIndex(0);
    } else {
      setPos(null);
      setIndex(0);
    }
  }


  
	return (

		<>
			<div 
				className="rich-textarea"
				ref={wrapper}
			>
				<BaseRichTextarea
				    style={{width:'100%'}}
			      value={text}
			      id={textareaElementId}
				    placeholder={placeholder}			      
			      onChange={(e) =>{
			      	setText(e.target.value)
			      	onTextChange(e.target.value)
			     	}}
			      ref={ref}
			      onKeyDown={onKeyDown}
			      onKeyUp={onKeyUp}
			      onSelectionChange={onSelectionChange}
			    >
		     {mentionRenderer}

		    </BaseRichTextarea>
		    {pos && createPortal(
	        <Menu
	          presentedItems={autoCompleteItems}
	          index={index}
	          complete={complete}
	          top={pos.top}
	        />,
	        // document.body
	        wrapper.current
	      )}

	      <a 
	      	href="#"
	      	onClick={()=>{
	      			setText(text.trim() + ' @');
	      			ref.current.focus();
	      			onSelectionChange(ref.current);
	      	}}
	      >@ mention</a>
	    </div>
			<h3 className="text-medium">Recent</h3>
				

				<UserBar
					items={friendsList}
					onClick={(item)=>onFriendClick(item)}
				/> 
    </>


		);
}

