import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';

import "@flaticon/flaticon-uicons/css/all/all.css";
import './App.scss';
// import App from './App';
import reportWebVitals from './reportWebVitals';





// Router imports
import {createBrowserRouter, RouterProvider} from "react-router-dom";


// Differnet views
import LandingPage from "./routes/landing-page-alt";
// import HomePage from "./routes/home-page";
import GridView from "./routes/grid-view";
import DirectMessages from "./routes/direct-messages";

import AnimationDemo from "./experiments/animation-demo";
import AnimationDemo2 from "./experiments/animation-demo-2";
import StyleGuide from "./experiments/style-guide";
import {LocalThumbnails} from "./experiments/local-thumbnails";
import {InviteFriends} from "./experiments/invite-friends-test";

import {Chunking} from './experiments/chunking';
// import {ReRenderBS} from "./routes/re-render-bs";
import {HomePage} from "./routes/home-page";

const router = createBrowserRouter([
  
  {
    path: "/",
    element: <LandingPage/>
  },
  {
    path: "/uploads",
    element: <Chunking/>
  },
  {
    path: "/home",
    element:  <HomePage/>
  },
  {
    path: "/grid",
    element: <GridView/>
  },
  {
    path: "/dm",
    element: <DirectMessages/>
  },
  {
    path: "/animation-demo",
    element: <AnimationDemo/>
  },
  {
    path: "/animation-demo-2",
    element: <AnimationDemo2/>
  },
  {
    path: "/styles",
    element: <StyleGuide/>
  },
  {
    path: "/invite-widget",
    element: <InviteFriends/>
  },
  {
    path: "/local-thumbnails",
    element: <LocalThumbnails/>
  },
  // {
  //   path: "/bs",
  //   element: <ReRenderBS/>
  // },
  // {
  //   path: "/test-new-feed",
  //   element: <TestNewFeed/>
  // }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();





