


import React, {useState, useEffect, useRef} from 'react';
// import { createPortal } from 'react-dom';

import {generateThumbnails} from '../utils/generate-thumbnails.js';
// import {generateThumbnails} from '../utils/generate-thumbnails.js';

export const LocalThumbnails = ({})=>{

	
	var fileRef = useRef(null);

	

	const onClick = ()=>{




		generateThumbnails(Array.from(fileRef.current.files), 10,200)



		.then((images)=>{

			console.log('generateThumbnails:promise', images);

			for( let i=0;i<images.length;i++)
			{
				var img = document.createElement('img');
				img.src = images[i].src;
				console.log(images[i].filename, img)


				document.body.appendChild(img)


			}
		})
	}
	return (
		<>
			<h1>LocalThumbnails</h1>
			<input
				ref={fileRef}
				type="file"
				accept=".mp4,.mov,.mkv"
				multiple
			/>
			<button
				className="button button-primary"
				onClick={()=>onClick()}
			>Generate</button>
		</>

		);
}