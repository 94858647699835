
import config from '../utils/config.app.js';




const checkForNewDMs = (session_id) =>{


    return  fetch(config.newDMsService+'?session_id='+session_id, {
        method: 'GET',
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        redirect: "follow",
        headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json',
        },
    })
    .then((response) => response.json())

}


const validateSession = (session_id) =>{


    return  fetch(config.validateSessionServiceUrl+'?session_id='+session_id, {
        method: 'GET',
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        redirect: "follow",
        headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json',
        },
    })
    .then((response) => response.json())
}


/*
  Updates nickname
*/
const saveNickname = (nickname, session_id)=>{

	// console.log('saveNickname', nickname, session_id);

	
    // console.log('onNicknameSubmit', nickname)


    return fetch(config.userServiceUrl+'?session_id='+session_id, {
          method: 'POST',
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          redirect: "follow",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name : nickname
          })
        })
        .then((response) => response.json())
}




  /*
    takes a search string and returns a promise eventualy returing a list of users matching the search string
    if searchstring is empty, return default users (friends)
  */
  const performUserSearch = (searchStr, session_id) =>{

    return fetch(config.userSearchService 
      + "?session_id=" + session_id
      + "&search="+searchStr,

      {
        method: "GET",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        redirect: "follow",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((response) =>{
        // console.log('userSearchService',searchStr, response)
        return response.results;
      }

      )
  }




/*
    Send the token to backend for validation
    the backend returns a json with user details

    token - Google Auth token
    isTestUser - if true, server forgoes the validation and creates a new user with email = token
    invite - if passed, assigns user to the invited post
  
*/
async function sendAuthToken(token, isTestUser=false, invite=false){
    
    

    let body = {
        token: token,
        test_user : isTestUser
      }

      if (isTestUser)
        body.test_user = true;

      if (!!invite)
        body.invite = invite

    return await fetch(config.verifyGoogleTokenServiceUrl, {
      method: 'POST',
      mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          redirect: "follow",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    })
  }



export  {checkForNewDMs,saveNickname, validateSession, sendAuthToken, performUserSearch}




