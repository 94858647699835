
import React, {useState, useRef, useEffect, memo} from 'react';


export const Chunking = ({})=>{
	var [files, setFiles ] = useState(null);





	function uploadChunk(order, chunk, retries = 3) {
		const formData = new FormData();
		formData.append('file', chunk);


		// try {
			// await

		// return fetch('//localhost:5556/chunk/'+order, {
		return fetch('//pineappel.com:5556/chunk/'+order, {
			method: 'POST',
			body: formData,
			mode: "cors", // no-cors, *cors, same-origin
			cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
			redirect: "follow",
			
		})


			
		// } catch (error) {
		// 	if (retries > 0) {
		// 	  	await uploadChunk(order, chunk, retries - 1);
		// 	} else {
		// 	  	console.error('Failed to upload chunk: ', error);
		// 	}
		// }
	}
	



	const onChange = (event)=>{
		// console.log(event.target.files);

		setFiles(event.target.files[0]);
	}

	const startUpload = ()=>{
		if (files===null)
		{
			alert('no files selected');
			return
		}

		const chunkSize = 1024 * 1024; // size of each chunk (1MB)
		let start = 0;
		let order = 0;
		let promisesBuf = []
		while (start < files.size) {
			promisesBuf.push(
				uploadChunk(order, files.slice(start, start + chunkSize))
			);
			start += chunkSize;
			order +=1
		}


		var t0 = performance.now();
		console.log('------- Upload started ----------')
		console.log('Size: ', files.size/1024/1024, 'Mb')
		console.log('Chunk size', chunkSize / 1024/1024, 'Mb')
		console.log('Chunks', Math.ceil(files.size/chunkSize));
		Promise.all(promisesBuf).then((v)=>{

			let tD = performance.now() - t0;
			


			console.log('Time:', tD/1000, 'sec');
			console.log('Speed:', files.size/tD *1000 /1024/1024, 'Mb/sec');
			console.log('Completed')
		})
	}



		// for (let i=0;i<files.length;i++)
		// 	{
		// 		// console.log('starting upload: ' , i)
		// 		let formData = new FormData()
				

		// 		// this must always be present..
		// 		formData.append('uploadto', uploadTo.key)
				

		// 		formData.append("file"+i, files[i])
		// 		postData(config.contentUploadServiceUrl+'?session_id='+user.getSessionID(), formData)
		// 		.then((response)=>{
		// 			setUploadPlaceholders( uploadPlaceholders.slice(1)); // removing one placeholder
		// 			setContentObj(response.post);
		// 			if (returnCollabAsync == true)
		// 			{
		// 				// if the popup was closed during upload.. trigger
		// 				// console.log('triggering feed update with closed popup')
		// 				onCollabCreated(response.post, uploadTo); 
		// 			}


		// 		});
		// 	}







	return (

		<>
			<h1>Ye</h1>


			<input
				type="file"
				accept=".mp4,.mov,.mkv"
				onChange={onChange}
			/>
			<button
				onClick={startUpload}
			>upload</button>
		</>
	);
}