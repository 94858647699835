
import config from '../utils/config.app.js';


const deletePost = (post_key, session_id)=>{

	return fetch(config.deletePostServiceUrl
		+'/'+post_key
		+'?session_id='+session_id, 
		{

			method: 'DELETE',
			mode: "cors", // no-cors, *cors, same-origin
			cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
			redirect: "follow",
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			}
		}).then((response) => response.json())

}

/*
	adds (isCreated = True) or removes (isCraeted=false) a new reaction to post
	currently used only for collab likes

*/
const onLikeClick = (post_key, isCreated ,session_id) =>{

 return fetch(
      config.reactionServiceUrl+'/'+ post_key + "?session_id=" + session_id,
      {
        method: "POST",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        redirect: "follow",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body : JSON.stringify({
        	is_created : isCreated,
        })
      }
    )
      .then((response) => response.json())



}




const getInvites = (post_key, session_id)=>{
	return fetch(
		config.inviteServiceUrl +
		"/" +
		post_key +
		"?session_id=" +session_id,
		{
			method: "GET",
			mode: "cors", // no-cors, *cors, same-origin
			cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
			redirect: "follow",
			headers: {
			  Accept: "application/json",
			  "Content-Type": "application/json",
			}
		}
	)
	.then((response) => response.json())


}



const setInvites = (post_key,user_keys, session_id)=>{

	console.log('setInvites', post_key, user_keys, session_id);

	return fetch(
	      config.inviteServiceUrl +
	        "/" +
	        post_key +
	        "?session_id=" +session_id,
	      {
	        method: "POST",
	        mode: "cors", // no-cors, *cors, same-origin
	        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
	        redirect: "follow",
	        headers: {
	          Accept: "application/json",
	          "Content-Type": "application/json",
	        },
	        body: JSON.stringify({
	          users: user_keys
	         
	        }),
	      }
	    ).then((response) => response.json())
	      

}


		
	     


const shareTo = (post_key, slide_keys, user_keys, message, session_id) =>{
	return fetch(
	      config.shareToServiceUrl +
	        "/" +post_key +
	        "?session_id=" +session_id,
	      {
	        method: "POST",
	        mode: "cors", // no-cors, *cors, same-origin
	        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
	        redirect: "follow",
	        headers: {
	          Accept: "application/json",
	          "Content-Type": "application/json",
	        },
	        body: JSON.stringify({
	          slide_keys 	: slide_keys,
	          invites 		: user_keys, 
	          message 		: message
	         
	        }),
	      }
	    ).then((response) => response.json())





}






/*
	removes (deletes) a slide from a post
*/
const removeSlide = (post_key, slide_key, session_id)=>{

	return fetch(config.deleteSlideServiceUrl
		+'/'+post_key
		+'/'+slide_key
		+'?session_id='+session_id, 
		{

			method: 'DELETE',
			mode: "cors", // no-cors, *cors, same-origin
			cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
			redirect: "follow",
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			}
		}).then((response) => response.json())


}


export {removeSlide, onLikeClick,deletePost, getInvites, setInvites, shareTo}



