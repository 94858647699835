
import React, {useState, useRef, useEffect, memo} from 'react';


import {LikeAnimation} from './like-animation';

import {ReactComponent as LikeIcon} from './assets/like.svg'
import {ReactComponent as LikeAltIcon} from './assets/like-alt.svg'
import {ReactComponent as ChatIcon} from './assets/chat.svg'
import {ReactComponent as AddFileIcon} from './assets/add-file.svg'
import {ReactComponent as DownloadIcon} from './assets/download.svg'
import {ReactComponent as ShareIcon} from './assets/share.svg'
import {ReactComponent as AddPeopleIcon} from './assets/add-people.svg'
import {ReactComponent as DeleteIcon} from './assets/delete.svg'


import {ReactComponent as LikeWhiteIcon} from './assets/like-white.svg'
import {ReactComponent as ChatWhiteIcon} from './assets/chat-white.svg'
import {ReactComponent as AddFileWhiteIcon} from './assets/add-file-white.svg'
import {ReactComponent as DownloadWhiteIcon} from './assets/download-white.svg'
import {ReactComponent as ShareWhiteIcon} from './assets/share-white.svg'
import {ReactComponent as AddPeopleWhiteIcon} from './assets/add-people-white.svg'
import {ReactComponent as DeleteWhiteIcon} from './assets/delete-white.svg'




import User from "../../utils/userdata.app.js";

import "./scss/content-actions.scss"


const empty_content_item = {
	liked_by:[],
	chat:[],
	slides:{}
}


export const ContentActions = function({content_item=empty_content_item, onClickHandler=()=>{}})
{
	



	let user = new User();
	let is_liked = content_item.liked_by.indexOf(user.getProperty('user_key'))>-1;




	let ca = [
		{
			action : 	'like',
			icon : 		is_liked ? <LikeAnimation  count={content_item.liked_by.length}/> : <LikeIcon/> ,
			icon_light: is_liked ? <LikeAnimation  count={content_item.liked_by.length}/> : <LikeWhiteIcon/> ,
			count : 	content_item.liked_by.length

		},
		{
			action: 	'toggle-chat',
			icon: 		<ChatIcon />,
			icon_light: <ChatWhiteIcon />,
			count : 	content_item.chat.length

		},
		{
			action: 	'add-file',
			icon: 		<AddFileIcon/>,
			icon_light: <AddFileWhiteIcon/>,
			count : 	null

		},
		{
			action: 	'add-people',
			icon: 		<AddPeopleIcon/>,
			icon_light: <AddPeopleWhiteIcon/>,
			count : 	null

		},
		{
			action: 'download',
			icon: <DownloadIcon/>,
			icon_light: <DownloadWhiteIcon/>,
			count : Object.keys(content_item.slides).length

		},
		{
			action: 'share',
			icon: <ShareIcon/>,
			icon_light: <ShareWhiteIcon/>,
			count : null

		},
		{
			action: 'delete',
			icon: <DeleteIcon/>,
			icon_light: <DeleteWhiteIcon/>,
			count : null

		}

	];

	return (<div className="content-actions">
				<ul>
					{ca.map((item,i)=>{
						return (
							<li key={i}>
								<button 
									onClick={(e)=>onClickHandler(item.action) }
								>
									<div className="show-on-desktop">{item.icon}</div>
									<div className="show-on-mobile">{item.icon_light}</div>
								</button>
								<span>{item.count}</span>
							</li>
						);
					} )}
				</ul>
			</div>
	);

}