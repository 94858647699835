import React, {useState, useRef, useEffect, memo} from 'react';
import {ReactComponent as PlayIcon} from './../../assets/play.svg'
import {Spinner} from "../../components/spinner.component";
import config from './../../utils/config.app.js';
import {checkIfSuccessful, dict2arr} from "../../utils/fetch.utils.js";

import "./scss/video-player.scss"



export const VideoPlayer = ({slide,/* videoKey,videoSrc, previewSrc, */isPlaying, controlsCallback})=>{
	
	// let slide ={duration:10, timeline_reactions:{}}
	let video = useRef(null);
	let timeline = useRef(null);
	var [isActuallyPlaying, setActuallyPlaying] = useState(false);
  var [isBuffering, setBuffering] = useState(false); 
  var [isHot, setHot] = useState(false);// this determines if user had interacted with the video and it should be loaded
  var [progress, setProgress] = useState(0);
  var [isDragging, setIsDragging] = useState(false);


  const onPlayClick = (slide_key, isPlaying)=>
  {
  	// console.log('onPlayClick')
  	controlsCallback(slide.slide_key, isPlaying ? 'pause' : 'play');
  }

  const controlsCallbackWrapper = (slide, context, params)=>
  {
  	controlsCallback(slide.slide_key, context, params);
  }

  const updateTimeElapsed = () => {
	if (video.current != null){
      setProgress(video.current.currentTime)  
    }
	}

	const getDuration = (video)=>
	{
		if (!video) return 0;
		return video.duration;
	}

	const raw2decimalTime= (time, duration)=>
	{
		// console.log('raw2decimalTime', time, duration)
		if (duration)
		{
			return Math.round(time / duration*100)  
		}
		else
		{
			return 0
		}

	}


	// converts fractional seconds (4.12) to a nice format of 4:xx
	const formatTime= (fracTime) => {

		const lz=(x, p)=>
		{
			while ((''+x).length < p ) x='0'+x
			return x
		}
		// [todo] fix this when we have video that is longer

		let seconds= Math.floor(fracTime)

		let msec = Math.round((fracTime - seconds)*1000);

		let minutes = Math.floor(seconds / 60)
		let hours = Math.floor(minutes / 60)

		seconds -= minutes * 60
		minutes -= hours * 60

		let hoursStr = hours >0 ? lz(hours,2)+':' : ''
		let minutesStr = lz(minutes,2)+':' 
		let secondsStr = lz(seconds,2)
		let msecStr =  '.'+lz(msec,4)



		return hoursStr+minutesStr+secondsStr//+msecStr

	}


	function onButtonClick() {
    // This will allow us to play video later...
    video.load();
    fetchVideoAndPlay();
  }



  // https://developer.chrome.com/blog/play-request-was-interrupted
	function fetchVideoAndPlay(src) 
	{
		console.log('fetchVideoAndPlay', src);
		setBuffering(true)
		fetch(src)
			.then(response => response.blob())
			.then(blob => {
			  video.srcObject = blob;
			  return video.play();
			})
			.then(_ => {
			  // Video playback started ;)
			   setBuffering(false);
			   setActuallyPlaying(true)
			})
			.catch(e => {
			  // Video playback failed ;(
			   setBuffering(false);
			   console.log('playback failed..')
			})
	}


	const onTimelineClick = (e, timeline)=>
	{
		if (!slide.preview_url) return;
	      // e = Mouse click event.
    let rect = timeline.getBoundingClientRect();
    let x = e.clientX - rect.left; 

    // this is progress in %
    let prc = x / timeline.offsetWidth;



    // we should scroll to this timecode
    let timeCode = video.current.duration * prc;

		video.current.currentTime = timeCode;
		setProgress(timeCode)
	}


    useEffect(()=>{

    	if (!slide.preview_url) return;
			video.current.addEventListener("timeupdate", updateTimeElapsed);
			
	    	// console.log('isPlaying', isPlaying)
			if (isPlaying)
			{
				//fetchVideoAndPlay(config.contentServiceUrl+slide.preview_url)

				if (!isHot)
	      {
	        setHot(true);
	        setBuffering(true)
	      }

      	video.current.play().then(()=>{
          setActuallyPlaying(true)
          setBuffering(false)
        }).catch((e)=>{


        	console.log('Somethign went wrong with playing the video', e)
        })

	      
				
			}
			else
			{
				if (isActuallyPlaying)
				{
					video.current.pause()
					setActuallyPlaying(false)	
				}
				
			}

	})

  const onTimelineDragStart = (event) =>{

  	setIsDragging(true);
  	// onPlayClick(null, false);
  	// video.current.pause()
  	event.preventDefault()
  	event.stopPropagation();
  }

  const onTimelineDragEnd = (event) =>{
  	// onTimelineClick(event, timeline.current);
  	setIsDragging(false);
  	event.preventDefault();
  	event.stopPropagation();
  };
  const onTimelineDragging = (event)=>{
  	if (!slide.preview_url) return;

  	if (isDragging)
  	{

  		let rect = event.target.getBoundingClientRect();
  		let pos = event.clientX - rect.left;
  		let prc = pos / event.target.offsetWidth;

  		let timeCode = video.current.duration * prc;

			video.current.currentTime = timeCode;


  		console.log('onTimelineDragging', pos, prc)	
  	}
  	
  	// event.preventDefault();
  	// event.stopPropagation();
  	// return false;
  	event.preventDefault();
  }




	return (
		<div className="video-player">

			<video 
				ref={video} 
				poster={slide.placeholder ? slide.thumbnail_url : config.contentServiceUrl+slide.thumbnail_url}
				preload="auto"
				muted  
				inline="true"
				playsInline
				loop >
				{isHot ? <source src={slide.placeholder ? slide.preview_url : config.contentServiceUrl+slide.preview_url} type="video/mp4" /> : null}
				
			</video>
			<div 
              	className="controls-overlay" 
              	onClick={()=>onPlayClick(slide.slide_key, isPlaying)}
            >
            	<button 
	            	className="play-button"
	            	
	            >
	                {isHot 
	                  ? (isBuffering ? <Spinner/> :  (!isActuallyPlaying ? <PlayIcon /> : null))
	                  : <PlayIcon/>}
	                {}
	                
	               
              	</button>
				<div 
					ref={timeline}
					className={"timeline " + (isActuallyPlaying ? 'timeline-hidden' : '')}
					onClick={(event)=>onTimelineClick(event, timeline.current)}
					onMouseDown={onTimelineDragStart}
					onMouseUp={onTimelineDragEnd}
					onMouseLeave={()=>setIsDragging(false)}
					onMouseMove={onTimelineDragging}
				>
					<div className="progress" style={{width:raw2decimalTime(progress,  slide.duration)+'%'}}> </div>
					{dict2arr(slide.timeline_reactions).map((item)=>(
					      <div 
					        key={item.dict_key}
					        className="reaction_indicator"
					        style={{
					          left: ( raw2decimalTime(item.position, slide.duration)) + '%'
					        }}
					      >
					        {item.reaction}
					      </div>
					    )
					  )}
					{!isActuallyPlaying ? <div className="total-time">{formatTime(slide.duration)}</div> : null}
					{!isActuallyPlaying || true ? <div className="current-time">{formatTime(progress)}</div> : null}
				</div>
				{ !isActuallyPlaying && isHot && (progress>0)? (  
                <div className="reactions">
                  <ul>
                    {['😍','😀','🥰','👍','👏'].map((item,i)=>(
                      <li key={i}>
                        <a 
                          // onClick={(e)=>onReactionClick(e,item)} 
                          onClick={(event)=>{
                            controlsCallbackWrapper(slide, 'timeline_reaction', {reaction : item, position : progress} );
                            // event.stopPropagation();
                            event.preventDefault();
                          }}
                          href="#"
                        >
                          {item}
                        </a>
                      </li>)
                    )}
                  </ul>
                </div>) : null
              }



            </div>

		</div>
	);
}
