
import "./../components-css/spinner.scss"
export const Spinner = ({size="regular"})=> {



	return (
		<div className="spinner-wrapper">
			<div
				className= {"spinner " + size}
			>
			</div>
		</div>
	);
}