

/*
	a component that allows to put value in the clipboard
*/
export const CopyButton = ({value, label="Copy Me", icon=null, onClick=()=>{}})=>
{
	const onClickWrapper = (event)=>
	{
		navigator.clipboard.writeText(value).then(
			() => {},
			() => {
				console.error('Failed to copy');
		});
		onClick(event, value)
	}

	return (
		<button
			onClick={onClickWrapper}
		>{icon}<span>{label}</span></button>)
}
