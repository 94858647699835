
import React, {useState, useEffect, useRef} from 'react';


import {ReactComponent as LikeAltIcon} from './assets/like-alt.svg'

import {
	linear, 
	easeOutBack,
	easeOutSine,
	easeInElastic, 
	easeOutElastic,
	easeInQuart,
	easeOutQuart,
	easeInSine, 
	easeInCubic, 
	easeInOutBounce
} from 'easing-utils';



function animate2Html(initParams, onAnimationFrame)
{
	for (let i=0;i<initParams.length;i++)
	{
		initParams[i].start = performance.now()
		initParams[i].timeFraction = 0;
		initParams[i].delay = initParams[i].delay || 0;
		initParams[i].duration = initParams[i].duration || 1000;

	}

	const onFrame = (time)=>{

		for (let i=0;i<initParams.length;i++)
		{

			initParams[i].timeElapsed = time - initParams[i].start - initParams[i].delay;
			
			// if (initParams[i].timeElapsed < initParams[i].delay) continue;

			initParams[i].timeFraction = (initParams[i].timeElapsed - initParams[i].delay) / initParams[i].duration;	
			
			if (initParams[i].timeFraction > 1) initParams[i].timeFraction = 1;

			if (initParams[i].loop && (initParams[i].timeFraction >=1))
		    {
		    	initParams[i].start = performance.now()
		    }

			onAnimationFrame(initParams)

		}
	    requestAnimationFrame(onFrame)
	}
	requestAnimationFrame(onFrame)
}




export const LikeAnimation = ({count = 1, duration=1500, height = 200})=>
{
	// var obj = Array(3).fill().map((_,i)=>useRef(null));

	var container = useRef(null);
	

	const onAnimationFrame = (params)=>
	{
		for (let i=0;i<params.length;i++)
		{

			let cur = params[i];


			if (cur.timeFraction < 0)
			{
				// the delay is in progress
				cur.obj.style.display='none';
			}
			else
			{
				let x =  easeOutBack(cur.timeFraction)*cur.xSpread - cur.xSpread/2 + cur.xOrigin;
				let y =  -easeOutSine(cur.timeFraction)*cur.ySpread+cur.yOrigin;
					


				// console.log(params)
				cur.obj.style.display='initial';
				cur.obj.style.top=y+'px';
				cur.obj.style.left=x+'px';
				cur.obj.style.opacity = (1-cur.timeFraction);
				cur.obj.style.position='absolute'
			}
			
		}
		
		
	}
	useEffect(()=>{

		if (container !== null)
		{

			let initParams = {};


			let items = [...container.current.children],
				params = {
					loop:true,
					duration:duration,
					xSpread: 0,
		    		ySpread: height,
		    		xOrigin:0,
		    		yOrigin:-20
				};




			// console.log('setting..', items)
			



			animate2Html(
				items.map(item=>{return {obj:item,delay: Math.random()*1000, ...params}} ),
				onAnimationFrame,
			 )
		}

	})
	return (
		<>
			<div
				style={{
					position:'relative',
					width:0,
					height:0,
					
				}}
				ref={container}
			>
				{Array(count).fill().map((_,i)=> <LikeAltIcon key={i}/>)}
			</div>
			<LikeAltIcon/>
		</>

	);
}