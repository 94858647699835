import {VideoPlayer} from './video-player'
import {checkIfSuccessful, dict2arr} from "./../../utils/fetch.utils.js";
import "./scss/invitation.scss"




export const InvitationComponent = ({item,invitesHandler, controlsCallback, currentlyPlaying})=>{


	



	const shouldSlideBePlaying = (slide,currentlyPlaying)=>{
		let isPlayingCurrentSlide = false
		if (currentlyPlaying[0] === true){ // we shall play this post..
			return true

		}
		return isPlayingCurrentSlide;
	}



	let slide = dict2arr(item.slides)[0];

	

	let message = '';

	// console.log('the item is', item);

	if (item.chat.length > 0)
		message = item.chat[0].message
	
	return (
		<div
			className="post-component invitation-post"
			id={item.key}
		>
			{process.env.NODE_ENV == 'development' ? (
			<div className="debug">
				<div>
					<a 
						href="#"
						onClick={(e)=>{
							console.log(item)
						}}
					>
						{item.key} : {item.is_invitation ? 'invite': 'collab'}
					</a>
				</div>
			</div>

			) : null}




			<VideoPlayer
				key={slide.slide_key}
				slide={slide}
				isPlaying={shouldSlideBePlaying(slide, currentlyPlaying)}
				controlsCallback={(videoKey, context, params)=> controlsCallback(item, slide, context, params)}

			/>


			<div className="invite-prompt">
				<div className="header">
					<h3>Invitation</h3>
				</div>
				<div className="body">
					<img 
						className="avatar"
						src={item.owner_avatar}
					/>
					<div className="name">{item.owner_name}</div>
					<div className="message">{message}</div>

					
				</div>
				<div className="footer">
					
					<button 
						className="button"
						onClick={()=>invitesHandler(item, false)}
					>Decline</button>
					<button 
						className="button button-primary"
						onClick={()=>invitesHandler(item, true)}
					>Accept</button>

				</div>
			</div>




		</div>

		)
}
			