import User from './userdata.app.js'
// import { useNavigate } from "react-router-dom";

export default ()=>2+2

export function checkIfSuccessful  (response, onSuccessCallback, onFailureCallback){

	// console.log("checkIfSuccessful", response)
	if (response.success && response.success === true)
	{
		
		const successValue = onSuccessCallback(response)
		return successValue;
	}
	else
	{
		console.log("Failed request: ", response)
		const failureValue = onFailureCallback(response);

		if (response.error && response.error === 'session_is_missing')
		{
			let user = new User()
			user.wipeLocalSession();

			window.location.href= '/';
			

		}
		return failureValue;
	}

}




export const dict2arr = (dict)=>{
	let result = []

	// console.log('dict2arr input',dict )

	for (var key in dict)
	{
		// console.log('dict2arr', key, dict[key])
		let tmp = {...dict[key]}
		tmp.dict_key = key
		result.push(tmp)
	}

	// console.log('dict2arr output',result )
	return result
}


