
import '../components-css/user-bar.scss'
import {ReactComponent as RemoveIcon} from './../assets/remove.svg'






export const UserBar = ({items, onClick, showRemove=false})=>{
	

	return (
		<div className="user-bar">
		{items.slice(0,5).map(item=>{
			return (
				<a 
				  key={item.user_key}
					className="item" 
					href="#"
					onClick={(e)=>onClick(item)}
				>
					<div className="avatar">
						<img src={item.user_avatar} alt={item.name}/>
						
					</div>
					{showRemove ? <div className="remove"><RemoveIcon/></div>:null}
					<span className="name text-xsmall">{item.name}</span>
				</a>);

		})}
		</div>


	);
}