import React, {useState, useEffect, useRef} from 'react';
// import Home from './page/Home';
// import Signup from './page/Signup';
// import Login from './page/Login';
// import {SignIn} from './components/sign-in.component'



import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import 'bootstrap/dist/css/bootstrap.min.css';
import "./../components-css/animation-demo.scss"



import {ReactComponent as LikeAltIcon} from '../components/content-feed/assets/like-alt.svg'

// https://andrewraycode.github.io/easing-utils/gh-pages/
// import 'easing-utils/dist/easing.js'


// / Curly braces needed for import
import {linear, easeOutBack,easeOutSine,easeInElastic, easeOutElastic,easeInQuart,easeOutQuart,easeInSine, easeInCubic, easeInOutBounce } from 'easing-utils';

// Pass in a value from 0 - 1 and get the eased value back



function animate2({canvas, initParams, onAnimationFrame, duration, loop = true})
{
	// console.log('canvas',canvas)
	const ctx = canvas.getContext("2d");
	let start = [];


	for (let i=0;i<initParams.length;i++)
	{
		initParams[i].start = performance.now()
		initParams[i].timeFraction = 0;


		// mandatory params
		initParams[i].delay = initParams[i].delay || 0;
		initParams[i].duration = initParams[i].duration || 1000;

	}

	 




	const onFrame = (time)=>{

		ctx.clearRect(0, 0, canvas.width, canvas.height)	
		
		for (let i=0;i<initParams.length;i++)
		{
			initParams[i].timeElapsed = time - initParams[i].start;


			// if (time - initParams[i].start < initParams[i].delay)
			// {
			// 	initParams[i].timeFraction = 0;
			// 	console.log('delay..')
			// }
			// else
			// {
			initParams[i].timeFraction = initParams[i].timeElapsed / initParams[i].duration;	
			// }

			
			
			
			

			if (initParams[i].timeFraction > 1) initParams[i].timeFraction = 1;

			if (initParams[i].loop && (initParams[i].timeFraction >=1))
		    {
		    	initParams[i].start = performance.now()
		    }

			onAnimationFrame(ctx, initParams)

		}
	    requestAnimationFrame(onFrame)
	    // }
	}
	requestAnimationFrame(onFrame)

}







const Animation = ({initParams, drawFunc})=>{

	// console.log('Animation redraw...')

	var canvasEl = useRef(null);
	var player = useRef(null)
	var [animState, setAnimState] = useState(0)
	



	
    useEffect(() => {
    	const canvas = canvasEl.current;


    	player.current.play().catch(() =>{})

    	



    	animate2({
	    	canvas: canvas, 
	    	initParams: initParams,
	    	
	    	onAnimationFrame: drawFunc, 
	    	duration: 1000, 
	    	loop: true
    	})
    })

    

		

			return (
				<>
					<video 
						src="//localhost:5556/get_content/vqgvso3uBN6MGJpRn2Xd/0/preview?session_id=085f9e106746d94513a788cb9c5a6add6cfe8d75"
						controls
						autoplay
						loop
						ref={player}

></video>

						<canvas 
						ref={canvasEl}
						width={507}
						height={947}
						style={{
							width:'100%', 
							height:'100%'
						}}
					>
					</canvas>
				</>
					
			)
}




const flyingEmojisAnimationParams = (numEmojis, emojis, xSpread, ySpread)=>{

	const params = [];
    	for (let i=0;i<numEmojis;i++)
    	{
    		params.push({
    			duration: 2000 + Math.random()*500 - 500,
    			loop:true,
    			size: 30 + Math.random()*30,
    			xOffset:  Math.random()*xSpread - xSpread/2,
    			yOffset:  Math.random()*ySpread - ySpread/2,
    			emoji: emojis[i % emojis.length],
    			origin: [200, 950],

    		})
    	}

    	return params;
}


const flyingEmojisAnimation = (ctx, params)=>{
	let x,y;
	// console.log('animate: ')
	

	for (let i=0;i<params.length;i++){
			// console.log(params[i].emoji)

			x =  params[i].origin[0] - easeOutBack(params[i].timeFraction)*params[i].xOffset;
			y =  params[i].origin[1] - easeOutSine(params[i].timeFraction)*params[i].origin[1]
			
			ctx.font = params[i].size+"px Arial";
			ctx.fillText(params[i].emoji, x , y );
		}	

}



const droppingEmojisAnimationParams = (numEmojis, emojis)=>{

	const params = [];
    	for (let i=0;i<numEmojis;i++)
    	{
    		params.push({
    			duration: 4000 + Math.random()*2000 - 1000,
    			// delay: Math.random()*1000,
    			loop:true,
    			size: 30,
    			xOffset:  Math.random()*500 - 250,
    			emoji: emojis[i % emojis.length],
    			origin: [200, 0]

    		})
    	}

    	return params;
}

const droppingEmojisAnimation = (ctx, params)=>{
	let x,y;
	// console.log('animate: ')
	

	for (let i=0;i<params.length;i++){
			// console.log(params[i].emoji)

			x =  params[i].origin[0] +params[i].xOffset;
			y =  params[i].origin[1] + easeInSine(params[i].timeFraction)*900
			
			ctx.font = params[i].size+"px Arial";
			ctx.fillText(params[i].emoji, x , y );
		}	

}

const flashingEmojisAnimation = (ctx, params)=>{
	let x,y;


	// console.log('animate: ')
	

	for (let i=0;i<params.length;i++){
	 		// console.log(params[i].emoji)

			x =  params[i].origin[0]// +params[i].xOffset;
			y =  params[i].origin[1]// + easeInSine(params[i].timeFraction)*900
			
			ctx.font = params[i].size+"px Arial";

			// console.log(params);

			if (Math.floor(params[i].timeElapsed / 500 ) % 2 === 0)
			{
				ctx.fillText(params[i].emoji, x , y );	
			}
			
		}	

}

const flashingEmojisAnimationParams = (numEmojis, emojis)=>{

	const params = [];
    	for (let i=0;i<numEmojis;i++)
    	{
    		params.push({
    			duration: 4000 + Math.random()*2000 - 1000,
    			// delay: Math.random()*1000,
    			loop:true,
    			size: 300,
    			// xOffset:  Math.random()*500 - 250,
    			emoji: emojis[i % emojis.length],
    			origin: [100, 450]

    		})
    	}

    	return params;
}


const runningEmojisAnimation = (ctx, params)=>{
	let x,y;


	// console.log('animate: ')
	

	for (let i=0;i<params.length;i++){
	 		// console.log(params[i].emoji)

			x =  params[i].origin[0]+linear(params[i].timeFraction)*500
			y =  params[i].origin[1] + Math.sin(params[i].yOffset+ params[i].timeFraction * Math.PI*2)*30 // + easeInSine(params[i].timeFraction)*900
			
			ctx.font = params[i].size+"px Arial";

				ctx.fillText(params[i].emoji, x , y );	
			
			
		}	

}

const runningEmojisAnimationParams = (numEmojis, emojis)=>{

	const params = [];
    	for (let i=0;i<numEmojis;i++)
    	{
    		params.push({
    			duration: 1000 + Math.random()*200 - 100,
    			// delay: Math.random()*1000,
    			loop:true,
    			size: 20,
    			// xOffset:  Math.random()*500 - 250,
    			emoji: emojis[i % emojis.length],
    			origin: [10, 450],
    			yOffset:Math.random()*Math.PI

    		})
    	}

    	return params;
}






function animate2Html(initParams, onAnimationFrame, duration, loop = true)
{
	// console.log('canvas',canvas)
	// const ctx = canvas.getContext("2d");
	let start = [];


	for (let i=0;i<initParams.length;i++)
	{
		initParams[i].start = performance.now()
		initParams[i].timeFraction = 0;
		initParams[i].delay = initParams[i].delay || 0;
		initParams[i].duration = initParams[i].duration || 1000;

	}

	 




	const onFrame = (time)=>{

		// ctx.clearRect(0, 0, canvas.width, canvas.height)	
		
		for (let i=0;i<initParams.length;i++)
		{
			initParams[i].timeElapsed = time - initParams[i].start;
			initParams[i].timeFraction = initParams[i].timeElapsed / initParams[i].duration;	
			
			if (initParams[i].timeFraction > 1) initParams[i].timeFraction = 1;

			if (initParams[i].loop && (initParams[i].timeFraction >=1))
		    {
		    	initParams[i].start = performance.now()
		    }

			onAnimationFrame(initParams)

		}
	    requestAnimationFrame(onFrame)
	    // }
	}
	requestAnimationFrame(onFrame)

}








const  LikeAnimationParams = (numEmojis, xSpread, ySpread)=>{

	const params = [];
    	for (let i=0;i<numEmojis;i++)
    	{
    		params.push({
    			duration: 2000 + Math.random()*500 - 500,
    			loop:true,
    			size: 30 + Math.random()*30,
    			xOffset:  Math.random()*xSpread - xSpread/2,
    			yOffset:  Math.random()*ySpread - ySpread/2,
    			// emoji: emojis[i % emojis.length],
    			origin: [200, 950],

    		})
    	}

    	return params;
}




const LikeAnimation = ({children})=>{

	let obj = useRef(null)
	// console.log(children)

	

	const LikeAnimation = (params)=>{
		let x,y;
		// console.log('animate: ')
		
		if (obj.current=== null) return;

		for (let i=0;i<params.length;i++){
				// console.log(params[i].emoji)

				x =  params[i].origin[0] - easeOutBack(params[i].timeFraction)*params[i].xOffset;
				y =  params[i].origin[1] - easeOutSine(params[i].timeFraction)*params[i].origin[1]
				

				obj.style = {top: y+'px', left:x+'px', position:'absolute'}
				// ctx.font = params[i].size+"px Arial";
				// ctx.fillText(params[i].emoji, x , y );
			}	

	}




	animate2Html(LikeAnimationParams(3, 50, 50) , LikeAnimation, 2000,true)


	return (

		<>
		<LikeAltIcon ref={obj}/>
			{children}
		</>
	);
}


export default function AnimationDemo() {
	return (
		<Container className="animation-demo">
			<Row>
			<Col>
			<h1>Pineappel will have the best reaction animations</h1>
			</Col>
			</Row>
			<Row>
				<Col>
					<div className="video-frame" 
						style={{
							position:'relative',
							overflow:'visible'
						}}
					>
						<button
							style={{
								position:'absolute',
								right:'-30px',
								top:'100px'


							}}
						>
							
							<LikeAnimation>
								<LikeAltIcon/>

							</LikeAnimation>
						</button>

						
						{/*<Animation 
							drawFunc={flyingEmojisAnimation} 
							initParams={flyingEmojisAnimationParams(10, ["😍","🥰","🥳","😱","💀","👏","💋","💔","💩","❄️"], 450,200)}
						/>*/}
					</div>
				</Col>
				
			</Row>
			<Row>
				<Col>
					<div className="video-frame">
						<Animation 
							drawFunc={flyingEmojisAnimation} 
							initParams={flyingEmojisAnimationParams(10, ["😍","🥰","🥳","😱","💀","👏","💋","💔","💩","❄️"], 450,200)}
						/>
					</div>
				</Col>
				<Col>
					<div className="video-frame">
						<Animation 
							drawFunc={flyingEmojisAnimation} 
							initParams={flyingEmojisAnimationParams(50, ["💔"], 500, 500)}
						/>
					</div>
				</Col>
			</Row>
			<Row>
				<Col>
					<div className="video-frame">
						<Animation 
							drawFunc={droppingEmojisAnimation} 
							initParams={droppingEmojisAnimationParams(30, ["💀"])}
						/>
					</div>
				</Col>
				<Col>
					<div className="video-frame">
						<Animation 
							drawFunc={flashingEmojisAnimation} 
							initParams={flashingEmojisAnimationParams(1,["😱"])}
						/>
					</div>
				</Col>
			</Row>
			<Row>
				<Col>
					<div className="video-frame">
						<Animation 
							drawFunc={runningEmojisAnimation} 
							initParams={runningEmojisAnimationParams(15, ["😍","🥰","🥳","😱","💀","👏","💋","💔","💩","❄️"])}
						/>
					</div>
				</Col>
				<Col>
					
				</Col>
			</Row>
		
		
		</Container>)
}