
import React, {useState, useRef, useEffect} from 'react';
import {ReactComponent as ConfirmActiveIcon} from './../assets/confirm-active.svg'
import Modal from 'react-bootstrap/Modal';
import {ReactComponent as CloseIcon} from './../assets/close.svg'
import {Input} from './form.input.component'
import {UserBar} from './user-bar.component'
import {getInvites} from '../services/post-service.js'
import User from '../utils/userdata.app.js'
import {performUserSearch} from '../services/user-service.js'
import {CopyButton} from './copy-button.component';
import {ReactComponent as CopyIcon} from './../assets/copy.svg'
import {setInvites} from '../services/post-service.js';

import '../components-css/add-people.scss'
import '../components-css/auto-complete.scss'


export const AddPeopleModal = ({addTo, isOpen, handleClose,flashMessageCallback}) =>{






	let [current, setCurrent] = useState([]);
	let [friends, setFriends] = useState([])
	let [autoCompleteItems, setAutoCompleteItems] = useState([])
	let [isChanged, setIsChanged] = useState(false);
	let searchInput = useRef(null);

	const performUserSearchWrapper=(searchStr)=>{
		let user = new User();
		return performUserSearch(searchStr, user.getSessionID())

	}


	useEffect(()=>{

		if (addTo == null) return;

		let user = new User();
		getInvites(addTo.key, user.getSessionID() ).then((response)=>{

			setCurrent(ensureUniqueUserKeys(response.collaborators))

		})

		performUserSearchWrapper("").then((friends)=>{
  			setFriends(ensureUniqueUserKeys(friends));
  		});




	}, [addTo])

	const ensureUniqueUserKeys = (list)=>
	{
		return list
				.reduce((acc, cur)=>
					// map the array to values only
					acc.map((a)=>a.user_key)
						// see if the current item.value is already in the acc
						.indexOf(cur.user_key)<0 
							? [...acc, cur] // add it 
							: acc, []) // skip it
	}

	const addToCurrent=(item)=>
	{
		setCurrent(ensureUniqueUserKeys([...current, item]))
		setFriends(friends.filter((a)=>a.user_key != item.user_key))
		setIsChanged(true)
	}

	const removeFromCurrent=(item)=>
	{
		setCurrent(current.filter(a=>a.user_key != item.user_key))
		setFriends(ensureUniqueUserKeys([...friends, item]))
		setIsChanged(true)
	}

	const onSearchChange=(event)=>
	{
		let search = event.target;
		if (search.value.length > 3)
		{
			performUserSearchWrapper(search.value).then((items)=>{


				console.log(items);
				setAutoCompleteItems(items)
			})
		}

	}
	const onSearchSelect=(i)=>
	{
		let item = autoCompleteItems[i];
		setCurrent([...current, item])
		setAutoCompleteItems([])
		setFriends(friends.filter((a)=>a.user_key != item.user_key))
		searchInput.current.value = ""
		// console.log(i, autoCompleteItems[i])
	}

	const Menu = ({ presentedItems, top, index, complete}) => 
	{
	  

	  if (presentedItems.length == 0) return <></>;
	  return (
	    <div
	    	className="auto-complete"
	      
	    >
	      {presentedItems.map((item, i) => (
	        <div
	        	className={"auto-complete-item " +(index === i ? 'active' : '')}
	          key={i}
	          // style={{top:top+'px'}}
	          onMouseDown={(e) => {

	            e.preventDefault();
	            complete(i);
	          }}
	        >
	        	<div
	        		className="avatar"
	        	>
	        		<img src={item.user_avatar} alt={item.name} />
	        	</div>
	        	<span className="name">{item.name}</span>
	        </div>
	      ))}
	    </div>
	  );
	};

	const updateInvites = (invites)=>
	{
		if (isChanged)
		{
			let user = new User();
			setInvites(addTo.key, invites.map(a=>a.user_key), user.getSessionID()).then(()=>{


				flashMessageCallback("Collaborators updated!")
			})
		}

		handleClose();
	}


	const CopyLinkButton = ({post, flashMessageCallback})=>
	{
		if (post === null) return null;


		return (
			<CopyButton

				value={  window.location.origin+'?invite='+ post.invite_hash}
				label="Copy link" //"Copy link"
				icon={<CopyIcon/>}
				onClick={()=>{
					flashMessageCallback('The invite link is copied to clipboard!')
				}}
			/>


			);
	}


	// console.log(addTo)
	return (
		<>
		      <Modal 
		      	className="add-people-modal"
		      	show={isOpen} 
		      	dialogClassName="small-modal"
		      	fullscreen={false}
		      >
		        <Modal.Header onHide={handleClose}>
		          <h3>Invite</h3>
		          <button className="btn-close" onClick={handleClose}><CloseIcon/></button>
		        </Modal.Header>
		        <Modal.Body>
		        	<h3 className="text-medium">Current</h3>
		         	
		         	<UserBar 
		         		items={current} 
		         		onClick={a=>removeFromCurrent(a)}
		         		showRemove={true}
		         	/>

		         	<h3 className="text-medium">Recent</h3>
		         	
		         	<UserBar 
		         		items={friends} 
		         		onClick={a=>addToCurrent(a)}
		         	/>

		        
		         

					<Input
						inputProps={{
							placeholder : "Search",
							type : 'search',
							onChange : onSearchChange,
							ref : searchInput
						}}
						onSearch={()=>{}}
					/>
					<div className="menu-wrapper">
					 <Menu
					 	presentedItems={autoCompleteItems}
					 	complete={(i)=>onSearchSelect(i)}
					 />
					</div>
					
					<CopyLinkButton post={addTo} flashMessageCallback={flashMessageCallback}/>
		        	
		        </Modal.Body>
		        <Modal.Footer>
		         


		          <button 
			      		className={"button roundButt active"}
			      		onClick={ ()=>updateInvites(current) }
			      	>
			      		<ConfirmActiveIcon/>
			      	</button>



		        </Modal.Footer>
		      </Modal>
		    </>
		)
}