import React, {useState, useEffect, useRef} from 'react';
// import Home from './page/Home';
// import Signup from './page/Signup';
// import Login from './page/Login';
// import {SignIn} from './components/sign-in.component'



import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { TopNav } from "../components/top-nav.component";
import { BottomNav } from "../components/bottom-nav.component";
// import { MentionableTextarea } from "../components/mentionable-textarea.component";

import { RichTextarea } from "../components/rich-textarea";
// import { SelectPeople } from "../components/select-people.component";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./../components-css/grid-view.scss"

import User from "../utils/userdata.app.js";
import config from "../utils/config.app.js";
import {dict2arr, checkIfSuccessful} from "../utils/fetch.utils.js";

import {ReactComponent as PlayIcon} from './../assets/play.svg'

import { UploadModal } from "../components/upload-modal/upload-modal-alt.component";
import Modal from 'react-bootstrap/Modal';
import {ReactComponent as CloseAltIcon} from './../assets/close-alt.svg'
// import { BottomNav } from "../components/bottom-nav.component";




// const options = [
//   { value: 'johndoe@example.com', label: 'JohnDoe', picture:"https://lh3.googleusercontent.com/a/ACg8ocJZUR1m3m0nrQZczon550jds7GlK5vCfIc73__kAsxHO9j2xBeh=s96-c" },
//   { value: 'janedoe@example.com', label: 'JaneDoe' , picture:"https://lh3.googleusercontent.com/a/ACg8ocJZUR1m3m0nrQZczon550jds7GlK5vCfIc73__kAsxHO9j2xBeh=s96-c"},
//   { value: 'sam_smith@example.com', label: 'SamSmith' , picture:"https://lh3.googleusercontent.com/a/ACg8ocJZUR1m3m0nrQZczon550jds7GlK5vCfIc73__kAsxHO9j2xBeh=s96-c"},
//   { value: 'alex.jones@example.com', label: 'AlexJones' , picture:"https://lh3.googleusercontent.com/a/ACg8ocJZUR1m3m0nrQZczon550jds7GlK5vCfIc73__kAsxHO9j2xBeh=s96-c"},
//   { value: 'emily_davis@example.com', label: 'EmilyDavis' , picture:"https://lh3.googleusercontent.com/a/ACg8ocJZUR1m3m0nrQZczon550jds7GlK5vCfIc73__kAsxHO9j2xBeh=s96-c"},
//   { value: 'michael.brown@example.com', label: 'MichaelBrown' , picture:"https://lh3.googleusercontent.com/a/ACg8ocJZUR1m3m0nrQZczon550jds7GlK5vCfIc73__kAsxHO9j2xBeh=s96-c"},
//   { value: 'sarah_wilson@example.com', label: 'SarahWilson', picture:"https://lh3.googleusercontent.com/a/ACg8ocJZUR1m3m0nrQZczon550jds7GlK5vCfIc73__kAsxHO9j2xBeh=s96-c" },
//   { value: 'daniel_johnson@example.com', label: 'DanielJohnson' , picture:"https://lh3.googleusercontent.com/a/ACg8ocJZUR1m3m0nrQZczon550jds7GlK5vCfIc73__kAsxHO9j2xBeh=s96-c"},
//   { value: 'laura.lee@example.com', label: 'LauraLee', picture:"https://lh3.googleusercontent.com/a/ACg8ocJZUR1m3m0nrQZczon550jds7GlK5vCfIc73__kAsxHO9j2xBeh=s96-c" },
//   { value: 'david_clark@example.com', label: 'DavidClark' , picture:"https://lh3.googleusercontent.com/a/ACg8ocJZUR1m3m0nrQZczon550jds7GlK5vCfIc73__kAsxHO9j2xBeh=s96-c"},
//   { value: 'daniel_johnson@example.com', label: 'AileenDaif', picture:"https://lh3.googleusercontent.com/a/ACg8ocJZUR1m3m0nrQZczon550jds7GlK5vCfIc73__kAsxHO9j2xBeh=s96-c" },
//   { value: 'laura.lee@example.com', label: 'Solvita' , picture:"https://lh3.googleusercontent.com/a/ACg8ocJZUR1m3m0nrQZczon550jds7GlK5vCfIc73__kAsxHO9j2xBeh=s96-c"},
//   { value: 'eriks@example.com', label: 'Eriks', picture:"https://lh3.googleusercontent.com/a/ACg8ocJZUR1m3m0nrQZczon550jds7GlK5vCfIc73__kAsxHO9j2xBeh=s96-c" }
// ]


// var defaultOptions = [
// 	{ value: 'AileenDaif@example.com', label: 'AileenDaif', picture:"https://lh3.googleusercontent.com/a/ACg8ocJcorpzbUjOiYFlwmxgBU5u-VysjDMrDkTAioytq3rsZcmLiA=s96-c" },
//   { value: 'Solvita@example.com', label: 'Solvita', picture:"https://lh3.googleusercontent.com/a/ACg8ocJZUR1m3m0nrQZczon550jds7GlK5vCfIc73__kAsxHO9j2xBeh=s96-c" },
//   { value: 'eriks@example.com', label: 'Eriks', picture:"https://lh3.googleusercontent.com/a/ACg8ocLaTalzBrMhu-vvz9rz3vpLFzLqVYfaL5q04mpwvwDbxumFFmOG=s96-c" }
// 	]



export const InviteFriends = ({}) => {

	
	var user = new User()
	var [defaultInviteOptions, setDefaultInviteOptions] = useState([]);


 /*
		takes a search string and returns a promise eventualy returing a list of users matching the search string
		if searchstring is empty, return default users (friends)
  */
	const performUserSearch = (searchStr) =>{


		return fetch(config.userSearchService 
			+ "?session_id=" + user.getSessionID()
			+ "&search="+searchStr,

      {
        method: "GET",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        redirect: "follow",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((response) =>{
      	console.log('userSearchService', response)
      	return response.results;
      }

			)
	}



// const filterOptions = (inputValue) => {
//   return options.filter((i) =>
//     i.label.toLowerCase().includes(inputValue.toLowerCase())
//   );
// };


// const promiseOptions = (inputValue) =>
//   new Promise((resolve) => {
//     setTimeout(() => {
//       resolve(filterOptions(inputValue));
//     }, 1000);
//   });




  var [selected, setSelected] = useState([])


  const onPersonAdded = (item)=>{
			let tmp = 
				[...selected, item]
					.reduce((acc, cur)=>
						// map the array to values only
						acc.map((a)=>a.user_key)
							// see if the current item.value is already in the acc
							.indexOf(cur.user_key)<0 
								? [...acc, cur] // add it 
								: acc, []) // skip it


	
		  setSelected(tmp)
			console.log('Selected', tmp)

  }

  const onItemRemoved = (item,i)=>{
  	let tmp = selected.filter((cur) => cur.user_key !== item.user_key)
  	setSelected([...tmp])
  }

  useEffect(()=>{

  	console.log('defaultInviteOptions',defaultInviteOptions);
  	if (defaultInviteOptions.length == 0 )
  	{

  		console.log('fetching default options')
  		performUserSearch('').then((response=>{

  			console.log('defaultOptions=',response);
  			setDefaultInviteOptions(response)
  		}))
  	}


  })

	return (
		<div>
	      <TopNav />
	      <Container>
	      	
	      <Modal 
		      	className="upload-modal"
		      	dialogClassName={"small-modal"}
		      	show={true} 
		      	fullscreen={false}
		      >
		      <Modal.Header >
		      <h3>Demo</h3>
		      <button className="btn-close" onClick={()=>{} }><CloseAltIcon/></button>
		      </Modal.Header>
		      <Modal.Body>
		      	<RichTextarea
		      		onMention={onPersonAdded}
		      		loadResults={performUserSearch}
		      	/>
		      	<h3>Current collaborators</h3>

		      {/*	<SelectPeople
		      		selected={selected}
		      		loadResults={performUserSearch}
		      		defaultOptions={defaultInviteOptions}
		      		onItemPicked={onPersonAdded}
		      		onItemRemoved={onItemRemoved}
		      	/>*/}
						
		      </Modal.Body>
		      <Modal.Footer>
		      	<button>Next</button>
		      </Modal.Footer>
		      
		      

		</Modal>


	      	 
	      </Container>
		  <BottomNav onAddClick={()=>{}}  />
		  
	    </div>)
}