import React, {state, useState, useEffect} from 'react';
import "./../components-css/flash-message.scss"

export const FlashMessage = ({message, timeout=2000, rnd, show=true, children=null})=>
{
	var [timerRef, setTimerRef] = useState(null);
	var [visible, setVisible] = useState(show);



	useEffect(()=>{

		if (show)
		{
			setVisible(true)
			// console.log('useEffect', show, visible)
			timerRef = setTimeout(()=>{

				setVisible(false)

			}, timeout );
		}
		

	},[children, message, rnd])
	// if (!showing) return null;

	return (
		<div 
			className={"flash-message" + ( visible ? ' show ' : '')}
			hidden={!show}
		>
			{children}
			<span className="message">{message}</span>
			}
		
		</div>
	)
}