
import React, {useState, useEffect, useRef} from 'react';
import {ReactComponent as ErrorIcon} from './../assets/input-error.svg'
import {ReactComponent as DeleteIcon} from './../assets/input-delete.svg'
import {ReactComponent as SearchIcon} from './../assets/search.svg'


import '../components-css/form.input.scss';

export const Input = ({
	subText="",
	validationErrorMsg = "",
	inputProps = {
		type:"text"
	},

	onSearch = ()=>{}

})=>
{
	const ref = useRef(null);
	const onIndicatorButtonClick = (event, input)=>{

		if (input.type === 'search')
		{
			onSearch(event, input.value)
		}
		else
		{
			input.value = '';
		}

	}
	return (
		<div 
			className={"form-input "}

		>

			<input 
				ref={ref}
				{...inputProps}
			/>

			<div className="description">
				<span className="regular">{subText}</span>
				<span className="error">{validationErrorMsg}</span>

			</div>

			<div 
				className="indicator"
			>


				<button
					onClick={(event)=>onIndicatorButtonClick(event, ref.current)}

				>
					{/*<ErrorIcon
						className="error"
					/> Aileen did not like this*/}
					<DeleteIcon
						className="delete"
					/>
					<SearchIcon
						className="search"
					/>
				</button>
			


			</div>




		</div>

	);


}