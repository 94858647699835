import { signInWithGooglePopup } from "../utils/firebase.utils";
import Button from 'react-bootstrap/Button';
import config from '../utils/config.app.js';

import {ReactComponent as GoogleIcon} from './../assets/google.svg'
import {checkIfSuccessful} from "../utils/fetch.utils.js";

import {sendAuthToken} from '../services/user-service'


function SignInWithGoogle(props) 
{
	


	const logGoogleUser = async () => {
	  const signInResponse = await signInWithGooglePopup();
	  // alert(response.user.displayName + '   ' + response.user.email);
	  // console.log(token)

	  const searchParams = new URLSearchParams(window.location.search);
	  sendAuthToken(signInResponse._tokenResponse.idToken, searchParams.get('invite'))
	  	.then((response) => response.json())
	  	.then((response) =>checkIfSuccessful(response,props.onSuccess))
	}


	const logTestUser = (user)=>{

		sendAuthToken(user, true)
	  	.then((response) => response.json())
	  	.then((response) => checkIfSuccessful(response,props.onSuccess, ()=>console.log('Auth failed')))
	}


	const TestUsers = ()=>{

		return(
			<div className="debug">
	 			<button onClick={(e)=>logTestUser('user1@gmail.com') }>Test user 1</button>
	 			<button onClick={(e)=>logTestUser('user2@gmail.com') }>Test user 2</button>
	 			<button onClick={(e)=>logTestUser('user3@gmail.com') }>Test user 3</button>
	 			<button onClick={(e)=>logTestUser('tony@gmail.com') }>Tony</button>
	 		</div>

		);
	}
  return (
  		<>
	 		<button className="button google-login-button"  onClick={logGoogleUser}>
	 			<GoogleIcon className="icon"/>
	 			<span>Continue with Google</span>
	 		</button>

	 		{process.env.NODE_ENV == 'development' ? <TestUsers /> : null}
	 		

 		</>

  )
}

export {SignInWithGoogle}
