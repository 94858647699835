
import React, {useState, useRef, useEffect} from 'react';

export const SelectFiles = ({onFilesSelected, id})=>{
	

	var fileSelect = useRef(null);

	const handleFileChange = (event)=>
	{
		
		// fileSelect.current.click()
		const selectedFiles = event.target.files;

    	if (selectedFiles && selectedFiles.length > 0) 
    	{
      		const newFiles = Array.from(selectedFiles);
		    // setFiles((prevFiles) => [...newFiles]);
		    onFilesSelected([...newFiles])
		}
	}





	return (
		<>
			<input
				id={id}
	            type="file"
	            hidden
	            ref={fileSelect}
	            onChange={handleFileChange}
	            accept=".mp4,.mov,.mkv"
	            multiple
	          />
		</>

	);
}