import React, {useState, useEffect, useRef, createContext, useContext} from 'react';
// import Home from './page/Home';
// import Signup from './page/Signup';
// import Login from './page/Login';
// import {SignIn} from './components/sign-in.component'



import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import 'bootstrap/dist/css/bootstrap.min.css';
import "./../components-css/animation-demo.scss"

// https://andrewraycode.github.io/easing-utils/gh-pages/
// import 'easing-utils/dist/easing.js'


// / Curly braces needed for import
// import {linear, easeOutBack,easeOutSine,easeInElastic, easeOutElastic,easeInQuart,easeOutQuart,easeInSine, easeInCubic, easeInOutBounce } from 'easing-utils';

// Pass in a value from 0 - 1 and get the eased value back








const AnimationContext = createContext({a:344, b:()=>alert('beeps')});



const Animation = ({frames, canvasWidth, canvasHeight, frameRate=33.333, onAnimationEnd, isPlaying})=>{

	
	var canvas= useRef(null)

	const runAnimation=(canvas, duration, onAnimationFrame)=>
	{
		
		var timeFraction = 0;
		const ctx = canvas.getContext("2d");
		var t0 = performance.now()

		const onFrame = (time)=>{
			
			ctx.clearRect(0, 0, canvas.width, canvas.height)
			let timeElapsed = time>= t0 ? time - t0 : 0 ;

			if (timeElapsed<=duration)
			{
				onAnimationFrame(ctx, timeElapsed)
				requestAnimationFrame(onFrame)
			}
			else
			{
				// console.log('reached the end')
				t0 = performance.now()
				if (onAnimationEnd())
				{
					timeElapsed = time>= t0 ? time - t0 : 0 ;
					onAnimationFrame(ctx, timeElapsed)
					requestAnimationFrame(onFrame)
				}
				else
				{
					// console.log('not looping')
				}
			}
		}
		requestAnimationFrame(onFrame)
	}


	// const {getFrames, frames} = useContext(AnimationContext);
		
	useEffect(()=>{
		// console.log('isPlaying', isPlaying)
		if (!isPlaying)
		{
			return 
		}
		// var frames = getFrames('whatever')


		var frameIndex = 0;
		const ctx = canvas.current.getContext("2d");
		canvas.current.width = canvasWidth;
		canvas.current.height = canvasHeight;
		

		console.log('frames', frames)
		if (frames.length == 0)
		{
			console.log('no frames to play')
			return
		}


		// the rate is 30fps...
		// so a new slide every 33 ms
		console.log('playing now..', )
	
		
		

		runAnimation(canvas.current ,frameRate * frames.length, (ctx, timeElapsed)=>{
			let frameIndex = Math.floor(timeElapsed /frameRate)
			if (frameIndex < frames.length)
			{
				// setFrameCounter(frameIndex)
				ctx.drawImage(frames[frameIndex], 0,0, canvasWidth, canvasHeight)
				// ctx.beginPath();
				// ctx.moveTo(0, 0);
				// ctx.lineTo(100, 100);
				// ctx.stroke();
			}
			else
			{
				console.log('not enough frames', frameIndex, frames.length)
			}
		})


	},[isPlaying])
// 
	return (

		<>
		<canvas
			ref={canvas}
			style={{
				border:'1px solid red',
				display:'block',
				width:'100%',
				height:'100%'
			}}
		/>
		</>
		)
	



}



	function preloadImages(urls) {
	  const promises = urls.map((url) => {
	    return new Promise((resolve, reject) => {
	      const image = new Image();

	      image.src = url;

	      image.onload = () => resolve(image);
	      image.onerror = () => reject(`Image failed to load: ${url}`);
	    });
	  });

	  return Promise.all(promises);
	}




const skipFrames = 2







export default function AnimationDemo() {

	var video = useRef(null);
	var [images, setImages] = useState([]);
	var [readyToPlay,setReadyToPlay] = useState(false);
	var [isPlaying, setIsPlaying] = useState(false);

	

	


	const AnimationProvider = ({children})=>{
		var [images, setImages] = useState([])

		console.log('contextprovider ')


		const genFilenames=(path, count)=>
		{
			let names = []
			for (var i=1;i<count;i+=skipFrames) // 65
			{
				let nr = i+''
				while (nr.length < 6){ nr = '0'+nr}
				let filename = path+nr+'.png';	
				names.push(filename)	
			}
			return names;
		}	

		

		useEffect(()=>{
			if (images.length == 0)
			{


				let frames = genFilenames('http://localhost:3000/static/animation2/frame-', 65)
				let t0 = new Date().getTime()
				preloadImages(frames).then((images)=>{
					console.log('ready to play', images.length)
					setImages(images)

					if (images.length == 0)
					{
						console.log('no frames to play')
					}
					else
					{
						// setReadyToPlay(true)
						var t1 = new Date().getTime()
						console.log('Frames are ready in ', (t1-t0)/1000, ' sec ')
					}
				})
			}

		})

		const getFrames = (animation)=>
		{
			console.log('getFrames',animation, images)
			if (!!animation)
			{
				return images
			}
			else
			{
				return images
			}
		}
		return (
			<AnimationContext.Provider value={{getFrames, frames:images}} >
				{children}
			</AnimationContext.Provider>

		)
	}



	const genFilenames=(path, count)=>
		{
			let names = []
			for (var i=1;i<count;i+=skipFrames) // 65
			{
				let nr = i+''
				while (nr.length < 6){ nr = '0'+nr}
				// let filename = 'http://localhost:3000/static/animation2/frame-'+nr+'.png'

				let filename = path+nr+'.png';	
				names.push(filename)	
			}
			return names;
		}	
	useEffect(()=>{
			if (images.length == 0)
			{


				let frames = genFilenames('http://localhost:3000/static/animation2/frame-', 65)
				let t0 = new Date().getTime()
				preloadImages(frames).then((images)=>{
					console.log('ready to play', images.length)
					setImages(images)

					if (images.length == 0)
					{
						console.log('no frames to play')
					}
					else
					{
						// setReadyToPlay(true)
						var t1 = new Date().getTime()
						console.log('Frames are ready in ', (t1-t0)/1000, ' sec ')
					}
				})
			}

		})


	return (

			<Container className="animation-demo">
				<Row>
				<Col>
				<h1>contex</h1>
				{/*<Dummy/>*/}
				{/*<h1>Pineappel will have the best reaction animations</h1>*/}
				</Col>
				</Row>
				<Row>
					<Col>
						<div className="video-frame">
							
							{/*<img className="bg" src="http://localhost:5556/get_content/zCq03T8uXGGVIDfjy4fg/0bc69879eba75154708680af7c40a0113bafb0bd/thumbnail?session_id=c8805e7598dd9edc53a8f2518a6d4940d5d62526"/>*/}
							{/*<video 
							ref={video}
							controls src="http://localhost:5556/get_content/9Wp4o9gh2gbOtI4RfRZg/414f4331ebc5a38615b6d220e2e74f6ca98e3180/preview?session_id=59c3c0c1ec0d63c6f968c5debe00fe55bc290b87"
							/>*/}
							<Animation
								frames={images}
								canvasWidth={507}
								canvasHeight={941}
								isPlaying={isPlaying}
								frameRate={33.33 * skipFrames}
								onAnimationEnd={  ()=>{
									setIsPlaying(false)
									return false;
								}}

							/>
						</div>
						<button onClick={()=>{
							video.current.play()
						}}>play video</button>
						<button onClick={()=>{
							setIsPlaying(true)
						}}>play animation</button>
					</Col>
					
				</Row>
			
			
			</Container>

		)
}