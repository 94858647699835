import React, {useState} from 'react';

import "./../components-css/enumerated-checkbox.scss"




const EnumeratedCheckbox = ({selected = false,text="", onChange})=>{



	const onClick = (e)=>{

		onChange(!selected)
	}

	return (<div 
				className={"enumerated-checkbox " + (selected ? 'selected' : '')}
				onClick={onClick}
			>
			{selected ? <span>{text}</span> : null }
			</div>)
}

export default EnumeratedCheckbox