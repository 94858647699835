import React, {useState, useEffect, useRef} from 'react';
// import Home from './page/Home';
// import Signup from './page/Signup';
// import Login from './page/Login';
// import {SignIn} from './components/sign-in.component'



import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { TopNav } from "../components/top-nav.component";
import { BottomNav } from "../components/bottom-nav.component";
// import { SearchInput } from "../components/search-input.component";
import { Input } from "../components/form.input.component";
import { UploadModal } from "../components/upload-modal/upload-modal-alt.component";

// import 'bootstrap/dist/css/bootstrap.min.css';
import "./../components-css/dm-view.scss"

import User from "../utils/userdata.app.js";
import config from "../utils/config.app.js";
import {dict2arr, checkIfSuccessful} from "../utils/fetch.utils.js";






const setUploadModalOpen = ()=>{}

const timeSince = (date) => {

  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}


const DMItem = ({item})=>
{

	// chat, replies, upload, timeline_reaction, invite

	let message = ''

	switch (item.kind)
	{
		case 'chat':
		case 'reply':
			message = item.message
		break;

		case 'upload':
			message = 'New video added'
		break;

		case 'timeline_reaction':
			message = 'Reacted '+item.reaction
		break;

		case 'invite':
			message = 'New collab request'
		break;
	}

	const onThumbnailClick = (event, item)=>
	{
		let dest = '/home'+'?post_key=' + item.post_key;

		if (!!item.slide_key)
			dest += '&slide_key='+item.slide_key;

		if (!!item.position)
			dest += '&tc='+item.position.raw



		document.location.href = dest
	}
	return (
		<div className="dm-item">
			
			<div className="avatar">
				<img src={item.sender_avatar} />
			</div>
			<div className="message">
				<span className={"sender-name "+(item.is_new ? 'new':'')}>{item.sender_name} </span>
				<span className="body">{message} • <span className="since">{timeSince(item.created_at * 1000)} ago</span></span>
			</div>
			<div className="thumbnail">
				<a href="#" onClick={(event)=>onThumbnailClick(event, item)}>
					<img src={config.contentServiceUrl+item.thumbnail_url} />
				</a>
			</div>
		</div>

		)
}
export default function DirectMessages() {

	let [feed, setFeed] = useState([]);
	var [uploadModalOpen, setUploadModalOpen] = useState({open:false, uploadTo:null});

	var user = new User();

	const fetchFeedData = (user)=> {
	
	return fetch(
	  config.DMFeedServiceUrl + "?session_id=" + user.getSessionID(),
	  {
	    method: "GET",
	    mode: "cors", // no-cors, *cors, same-origin
	    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
	    redirect: "follow",
	    headers: {
	      Accept: "application/json",
	      "Content-Type": "application/json",
	    },
	  }
	)
	  .then((response) => response.json())
	  .then((response) =>
	    checkIfSuccessful(
	      response,
	      function (r) {
	        return r;
	      },
	      function (r) {
	        return { items: [] };
	      }
	    )
	  );
	}

	function onUploadDone(post, uploadTo) 
	{
		console.log('done',post)
		
		let slides = dict2arr(post.slides);
		let dest = '/home'+
								'?post_key=' + post.key+
								'&slide_key='+slides[0].slide_key;


		document.location.href = dest

	}


	useEffect(()=>{
		let user = new User();
		fetchFeedData(user).then((results)=>{

			// console.log(results.items)
			setFeed(results.items)
		})
	},[])

	const onSearch = (event, input)=>
	{
		alert('You searched for: '+input);
	}
	return (
		<>
			<TopNav 
        user={user}
        homeUrl="/home"
        gridUrl="/grid"
        navHidden={true}

      />
			<div className="dm-view">
				

				<div className="mid-row">
					<div className="search-form">
						<Input onSearch={onSearch} inputProps={{type:'search'}}/>
					</div>
					{feed.map((item, i)=><DMItem key={i} item={item}/>)}
				</div>


		     
		    </div>
		    <BottomNav 
		    	onAddClick={() => setUploadModalOpen({open:true, uploadTo:null, key:  new Date().getTime() }) } 
		    	homeUrl="/home"
        	dmUrl="/dm"
		    />
		 	<UploadModal
		  		key={uploadModalOpen.key}
		        open={uploadModalOpen.open}
		        handleClose={() => setUploadModalOpen({open:false, uploadTo:null})}
		        // onUploadDone={onUploadDone}
		        onCollabCreated={onUploadDone}
		        returnCollabAsync={false}
		        uploadTo={null}
		        welcomeH1={ uploadModalOpen.uploadTo===null ? "Start a new collab" : "Add files to a collab"}
        		modalH3={ uploadModalOpen.uploadTo===null ? "Start a new collab" : "Add files to a collab"}

		      />
	    </>
	);
}