import React, {useState, useRef, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';

import config from '../../utils/config.app.js';
import {Spinner} from "../../components/spinner.component";
import {checkIfSuccessful} from "../../utils/fetch.utils.js";
import {UserBar} from '../user-bar.component'

import User from '../../utils/userdata.app.js'
import {ReactComponent as CloseIcon} from './../../assets/close.svg'

import {ReactComponent as ConfirmIcon} from './../../assets/confirm.svg'
import {ReactComponent as ConfirmActiveIcon} from './../../assets/confirm-active.svg'
import {ReactComponent as CopyIcon} from './../../assets/copy.svg'
import {ReactComponent as AddIcon} from './../../assets/add.svg'
import {ReactComponent as RemoveIcon} from './../../assets/remove.svg'

import {performUserSearch} from '../../services/user-service.js'


// import { MentionableTextarea } from "../../components/mentionable-textarea.component";
import { RichTextarea } from "../../components/rich-textarea";
// import { SelectPeople } from "../../components/select-people.component";

import {MediaPreviewGrid} from './../media-preview-grid.component'
import {CopyButton} from '..//copy-button.component';
import {FlashMessage} from '../flash-message.component'
import {setInvites as setInvitesService} from '../../services/post-service.js'


import {dict2arr} from "../../utils/fetch.utils.js";

export const UploadDetails = ({
	uploadTo, 
	triggerNextStep, 
	flashMessageCallback, 
	onRemoveSlide,
	showDescriptionAndInvites

}) =>{

	// var [response, setResponse] = useState(null);
	var [invites, setInvites] = useState([]);
	// var [currentSlides, setCurrentSlides] = useState(uploadTo.slides)
	// var [defaultInviteOptions, setDefaultInviteOptions] = useState([]);

	var [recentInvites, setRecentInvites] = useState([]);
	// var [injectedInvites, setInjectedInvites] = useState([])
	var user = new User();



	// console.log(response, !!response)
	const thumbnailRenderer = (item) =>{
		// console.log('item',item);
		if (item.add_file == true)
		{
			return (
				<div className="add-file" onClick={()=>addFileToCollab(uploadTo)}>
					<i><AddIcon/></i>
				</div>
			)
		}

		// if (item.placeholder==true)
		// {
		// 	// no more placehoders here
		// 	return (
		// 		<div className="uploading">
					
		// 			<img src={item.src} style={{width:'100%'}} />
		// 			<span className="label">{item.filename}</span>
		// 			<Spinner/>
		// 		</div>
		// 	)
		// }
		// else
		// {
			return (
				<>
					<img 
						src={item.placeholder ? item.miniature_url : config.contentServiceUrl+item.miniature_url} 
						style={{width:'100%'}} 
					/>
					<span className="label">{item.original_filename}</span>
					{item.placeholder 
					? <Spinner/> 
					: (
						<button 
						className="remove-butt"
							onClick={()=>{
								onRemoveSlide(item.slide_key);
								flashMessageCallback("Item is deleted!")
						}}
						>
							<RemoveIcon/>
						</button>
					)}


					
				</>
				)
		// }
		
	}

	const addFileToCollab = (uploadTo)=>
	{
		document.getElementById('selectFilesElement').click()
		// alert('adding files here');
	}
  function chatMessageSubmitHandler(message, parent, content_item) {
    // console.log("chatMessageSubmitHandler", message, parent, content_item);

    return fetch(
      config.chatSubmitServiceUrl +
        "/" +
        content_item.key +
        "?session_id=" +
        user.getSessionID(),
      {
        method: "POST",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        redirect: "follow",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message: message,
          parent: parent,
        }),
      }
    )
      .then((response) => response.json())
      .then((response) =>
        checkIfSuccessful(
          response,
          // if the post was successful
          function (r) {




          	// setResponse(r)
          	return r;
            // console.log('message is submitted', response);
            // updatePost(content_item.key, (post) => {
            //   return { chat_open: true, ...r.post };
            // });
          },
          function (r) {
            console.error("something was wrong..");
          }
        )
      );
  }





	const submitPostDetails = ()=>{

		
		if (!uploadTo){
			// this should never happen as the content obj should exist as this point
			console.log('Post is not ready yet')
			return;
		}

		if (showDescriptionAndInvites == false)
		{
			// this is a simplified, add-files form
			triggerNextStep(uploadTo);
			return
		}

		if (invites.length == 0)
		{
			// we allow to not invite 
			// alert('You must invite someone to collaborate!')
			// return
		}
		else
		{
			setInvitesService(uploadTo.key, invites.map((invite)=>invite.user_key), user.getSessionID())


		}
		
		let description = document.getElementById('descriptionElement').value;
		
		if (description.length > 0)
		{
			// submit as chat message..
			// console.log('chat. uploadto= ', uploadTo);
			chatMessageSubmitHandler(description, null, uploadTo )
			.then((r)=>{
				// console.log('updating chat', r);

				let newPost = {...uploadTo, chat: r.post.chat}
				triggerNextStep(newPost);


				})
		}
		else
		{
			// console.log('not updating chat', uploadTo)
			triggerNextStep(uploadTo);
		}
		
	}


	const prepareItemsForGrid = (post) => {


		// console.log('prepareItemsForGrid', uploadPlaceholders, uploadTo)
		let addFile = {add_file: true, key:'add_file'}

		// console.log('uploadsInProgress', uploadsInProgress)
		// let filePlaceholders = uploadsInProgress.map((item,i)=>{return {placeholder : true, key:item.filename,...item}});

		let currentSlides = dict2arr(post.slides).map((a)=>{return  {key:a.slide_key, ...a}} )

		console.log('currentSlides', currentSlides);
		
		// return [addFile,...filePlaceholders,...currentSlides ]
		return [addFile,...currentSlides ]
		
		

	}
	



const performUserSearchWrapper=(searchStr)=>{

	return performUserSearch(searchStr, user.getSessionID())

}

const injectedElement=(self)=>{

	console.log('injectedElement', self)
}

   const DescriptionAndInvites = ()=> {


  	return (
  		<>
  			<div className="form-description">


  				<RichTextarea
  					textareaElementId="descriptionElement"
  					mentionRegExp={config.MENTION_REGEXP}
		       	performUserSearch={performUserSearchWrapper}
		       	onTextChange={(text)=> {}}
		       	onInvitesChange={(invites)=>setInvites(invites)}
		       	placeholder="Add description"
		       	recentInvites={recentInvites}
		      />

		      		
				</div>

				<CopyButton

					value={  window.location.origin+'?invite='+ uploadTo.invite_hash}
					label="Copy link" //"Copy link"
					icon={<CopyIcon/>}
					onClick={()=>{
						flashMessageCallback('The invite link is copied to clipboard!')
					}}
				/>
			</>
			);
  }



  useEffect(()=>{
  	// fetching friends
  	performUserSearchWrapper("").then((friends)=>{
  		setRecentInvites(friends);

  	},[]);
  	


  }, [uploadTo])

	return (
		<>
			<Modal.Body>
				{/*<h3>UploadInProgress {uploadProgress.completed} </h3>*/}
				
				<div className="media-grid-wrapper">
					<MediaPreviewGrid 

						items={prepareItemsForGrid(uploadTo)} 
						thumbnailRenderer={thumbnailRenderer}
					/>
				</div>

				{ showDescriptionAndInvites ? DescriptionAndInvites() : null }
				
			</Modal.Body>
			<Modal.Footer>
			      	<button 
			      		className={"button roundButt active"}
			      		onClick={submitPostDetails}
			      	>
			      		<ConfirmActiveIcon/>
			      	</button>
			</Modal.Footer>

		</>


			
		)
}
