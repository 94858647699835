


import config from '../utils/config.app.js';
import React, {useState, useEffect} from 'react';
// import Nav from 'react-bootstrap/Nav';

import User from '../utils/userdata.app.js'
import Modal from 'react-bootstrap/Modal';
import EnumeratedCheckbox from './enumerated-checkbox.component'
import {ReactComponent as CloseIcon} from './../assets/close.svg'
import {MediaPreviewGrid} from './media-preview-grid.component'
import {dict2arr} from "../utils/fetch.utils.js";
import { RichTextarea } from "../components/rich-textarea";
import {performUserSearch} from '../services/user-service.js'
import {ReactComponent as ConfirmIcon} from './../assets/confirm.svg'
import {ReactComponent as ConfirmActiveIcon} from './../assets/confirm-active.svg'
import {Spinner} from "./spinner.component";
import {shareTo} from '../services/post-service'

import "./../components-css/share-to-modal.scss"



function ShareToModal({isOpen, content_item, handleClose, onDownload, onCollabShared, flashMessageCallback})
{
	var [invites, setInvites] = useState([]);
	var [recentInvites, setRecentInvites] = useState([]);

	// console.log('citem', content_item);
	var [selectedFiles, setSelectedFiles] = useState([])

	useEffect(() => {
		if (content_item !== null)
		{
			setSelectedFiles([Object.keys(content_item.slides)[0]])
		}
		
	},[content_item])



	const onSelect = (slide, isSelected)=>{

		// console.log('onSelect', slide.slide_key, isSelected)

		if (isSelected)
		{
			// console.log('Selecting..', slide.slide_key)
			setSelectedFiles([...selectedFiles,slide.slide_key])
		}
		else
		{
			// console.log('DESelecting..', slide.slide_key, selectedFiles, selectedFiles.filter((item)=>item===slide.slide_key ))
			setSelectedFiles(selectedFiles.filter((item)=>item!==slide.slide_key ))
			

		}
	}


	


	const thumbnailRenderer = (item)=> {
		// console.log('thumbnailRenderer', item)
		return (
			<>

				<img 
					className={item.is_downloaded ? 'faded': ''}
					src={item.placeholder? item.miniature_url:config.contentServiceUrl+item.miniature_url} 
					style={{width:'100%'}} 
				/>
				{item.placeholder 
					? <Spinner/>
					: (
						<EnumeratedCheckbox 
							onChange={(state)=>onSelect(item, state)}
							selected={selectedFiles.filter((i)=>i==item.slide_key).length>0}
							text={selectedFiles.indexOf(item.slide_key)+1}
						/>
					)}
			</>
			);
	}

	const selectAll = (slides)=>{


		if (selectedFiles.length == slides.length)
		{
			setSelectedFiles([]);
		}
		else
		{
			setSelectedFiles( slides);
		}
	}


	const performUserSearchWrapper=(searchStr)=>{

		let user = new User()
		return performUserSearch(searchStr, user.getSessionID())

	}


	const submitShareTo = ()=>
	{
		let message = document.getElementById('descriptionElement').value;
		let slide_keys = selectedFiles;
		let user_keys = invites.map(a=>a.user_key);
		let session_id = new User().getSessionID();

		shareTo(content_item.key, slide_keys, user_keys, message, session_id).then((response)=>{

			flashMessageCallback('Collab shared!')
			handleClose();
			onCollabShared(response.post);
		})






	}
	useEffect(()=>{
	  	// fetching friends
	  	performUserSearchWrapper("").then((friends)=>{
	  		setRecentInvites(friends);

  		});
  	}, [isOpen])



	var isSubmitButtActive = (invites.length > 0)

	if (content_item==null) return null;
	return (
		    <>
		      <Modal 
		      	className="share-to-modal"
		      	show={isOpen} 
		      	dialogClassName="small-modal"
		      	fullscreen={false}
		      >
		        <Modal.Header onHide={handleClose}>
		          <div className="select-all">
		          	<a
		          		onClick={()=>selectAll( Object.keys(content_item.slides)  )}
		          		href="#"
		          		className={Object.keys(content_item.slides).length < 2 ? 'hidden' : ''}
		          	>
			          	<EnumeratedCheckbox 
							onChange={()=>{}} //{(state)=>onSelect(item, state)}
							selected={content_item && selectedFiles.length == dict2arr(content_item.slides).length} //{selectedFiles.filter((i)=>i==item.slide_key).length>0}
							text="" //{selectedFiles.indexOf(item.slide_key)+1}
						/>
						<span>Select all</span>
					</a>
		          </div>
		          <h3>Share to</h3>
		          <button className="btn-close" onClick={handleClose}><CloseIcon/></button>
		        </Modal.Header>
		        <Modal.Body>
		         <div className="list-container">
			        <MediaPreviewGrid 
						items={!!content_item ? dict2arr(content_item.slides): []} 
						thumbnailRenderer={thumbnailRenderer}
					/>
			    </div>
			    <RichTextarea
  					textareaElementId="descriptionElement"
  					mentionRegExp={config.MENTION_REGEXP}
			       	performUserSearch={performUserSearchWrapper}
			       	onTextChange={()=>{}}
			       	onInvitesChange={(invites)=>setInvites(invites)}
			       	placeholder="Add description"
			       	recentInvites={recentInvites}
			      />

			    

		        </Modal.Body>
		        <Modal.Footer>
		          

		          <button 
			      		className={"button roundButt " +(isSubmitButtActive ? 'active' : '')}
			      		onClick={submitShareTo}
			      		disabled={!isSubmitButtActive}
		      	>
		      	{isSubmitButtActive ? <ConfirmActiveIcon/> : <ConfirmIcon/>}
		      	</button>


		        </Modal.Footer>
		      </Modal>
		    </>
		  );
} 



export {ShareToModal}

