





const generateThumbnail = (file, timecode = 1, width=null, height=null) =>{


	const resize=(origWidth, origHeight, targetWidth, targetHeight)=>{
		let ratio = origWidth/origHeight;
		return {
			width : !!targetWidth ? targetWidth : (!!targetHeight ? targetHeight * ratio : origWidth ),
			height : !!targetHeight ? targetHeight : (!!targetWidth ? targetWidth / ratio : origHeight )
		}
	}

	const captureVideo = (video, canvas, width, height)=>
	{
		let thumbnail = resize(video.videoWidth, video.videoHeight, width, height)
		canvas.width = thumbnail.width;
		canvas.height = thumbnail.height;
		canvas.style.width = thumbnail.width + 'px';
		canvas.style.height = thumbnail.height + 'px';
		canvas.getContext('2d').drawImage(video, 0, 0, thumbnail.width, thumbnail.height);
		// video.src=null;
		return {filename: file.name, src: canvas.toDataURL("image/png")}
	}



	var video = document.createElement('video');
	var canvas = document.createElement('canvas');


	

	// canvas.style.border = '4px solid red'
	// video.style.width='200px'
	// video.style.border = '4px solid green'

	// console.log('generateThumbnail', file)


	
	// document.body.appendChild(video)
	// document.body.appendChild(canvas)

	video.muted = true;
	video.src = URL.createObjectURL(file);
	video.currentTime = timecode;

	console.log('generateThumbnail','about to hit play', video)

	const templateResponse = {src: null, filename:file.name }
	let videoPromise = video.play()
		.then(()=>{
			// On safari, there is a delay between promise and the actual playing of the video
			// 500ms seem to fix this

			let capturedVideo = captureVideo(video, canvas, width, height);
			video.src = null;
			return new Promise((resolve) => setTimeout(() =>resolve(capturedVideo), 500));
		})
		.catch((k)=>{
			// this happens when video format is not supported by the browser
			// apparently Safari does not support mkv
			video.src = null;
			return new Promise((resolve)=>resolve({src: null, filename:file.name }) )
		});
	let fallbackPromise =  new Promise((resolve)=> setTimeout(() =>{
		console.log('generateThumbnail: fallbackPromise')
		return resolve(templateResponse)
	}, 2000));

	return Promise.any([videoPromise, fallbackPromise]);



	
}



export const generateThumbnails = (files, timecode, width, height)=>{
	return Promise.all( files.map((file)=> generateThumbnail(file, timecode, width, height) ) )
}

// export default {generateThumbnail, generateThumbnails}