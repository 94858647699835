// this class manages persistent handling of user data
// currently a wrapper around localStorate
import config from './config.app.js';

class User
{
	constructor(data)
	{
		for (let key in data)
		{
			this.setProperty(key, data[key])
		}

		// console.log('New user initialized: ', data)
	}


	getProperty(name, defaultValue)
	{
		return localStorage.getItem(name) || defaultValue
	}

	setProperty(name, value)
	{
		localStorage.setItem(name, value);
	}

	getSessionID()
	{
		return localStorage.getItem('session')
	}


	Avatar()
	{
		return (<img src={this.getProperty('picture','')} />)
	}
	// 
	// More specific methods
	// 

	// Returns true if a valid user session is present
	hasSessionData()
	{
		// check if session exists locally
		return !!this.getProperty('session', false)
		//
		// Maybe we do not need to check session against the backend?
		// Instead we could wipe session from the local storage when session id error ocurrs

		// if (!!this.getProperty('session', false))
		// {

		// 	console.log('hasSessionData')
		// 	const data = new URLSearchParams();
		// 	data.append("session_id", this.getProperty('session'),)

		// 	var r =  await fetch(config.validateSessionServiceUrl+'?session_id='+this.getProperty('session'), {
		// 		method: 'GET',
		// 	  	mode: "cors", // no-cors, *cors, same-origin
	    //   	  	cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
	    //   		redirect: "follow",
		// 	  	headers: {
		// 	    	'Accept': 'application/json',
		// 	    	'Content-Type': 'application/json',
		// 	  	},
		// 	}).then((response) => response.json())
	  	// 	.then((r)=>r.isValid)
	  	// 	console.log('rr=', r)

	  	// 	return r
	  		

		// }
		// else
		// {
		// 	return false
		// }
	}

	wipeLocalSession()
	{
		localStorage.removeItem("session");
		localStorage.removeItem("name");
		localStorage.removeItem("email");
		localStorage.removeItem("picture");
		// localStorage.removeItem("nickname");
		localStorage.removeItem("success");
		localStorage.removeItem("first_login");
		localStorage.removeItem("user_key");
	}

	logout()
	{
		fetch(config.logoutServiceUrl, {
		  method: 'POST',
		  mode: "cors", // no-cors, *cors, same-origin
	      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
	      redirect: "follow",
		  headers: {
		    'Accept': 'application/json',
		    'Content-Type': 'application/json',
		  },
		  body: JSON.stringify({
		    session_id: localStorage.getItem("session")
		  })
		})

		this.wipeLocalSession()
		



	}
}

export default User